import DashboardAPI from "../apis/DashboardAPI";
import { iAdminFormSubmissionsData, type AdminFormSubmissionsDataOptionsType } from '../contexts/AdminFormSubmissionsDataContext';
import { iTagDTO } from "../utilities/APIInterfaces";
import { Status } from "../utilities/FormEnums";


export type AdminFormSaveType = {
  uuid: string; // forms.UUID
  org_Id?: number | null;
  name: string;
  description?: string | null;
  start_Date: string;
  due_Date?: string | null;
  json?: string | null;
  userId?: number | null;
  academic_Aid_Year_Id?: number | null;
  verifier_Type_Id: number;
  visibility_Type_Id: number;
  priority_Id: number;
  billing_Type_Id?: number | null;
  invoice_Status_Ids?: number[] | null;
  version_Number?: number | null;
  tags?: iTagDTO[];
};

class AdminFormsService {

  async CreateForm(form: AdminFormSaveType, loader: (v: boolean) => void) {
    let response = null;

    loader(true);
    response = await DashboardAPI.callForData({
      method: 'POST',
      route: 'Form',
      body: form
    });
    loader(false)

    return response;
  }

  async UpdateForm(form: AdminFormSaveType, loader: (v: boolean) => void) {
    let response = null;

    if (form) {
      loader(true);
      response = await DashboardAPI.callForData({
        method: 'PATCH',
        route: 'Form',
        body: form
      });
      loader(false)
    }

    return response;
  }

    async GetAdminFormListData(opeid: string | undefined, loader: (v: boolean) => void) {
        let formData = null

        if(opeid != null) {
            loader(true);
            formData = await DashboardAPI
                .callForData({route: `adminForm/${opeid}`});
            loader(false); 
        }

        return formData;
    }

  async GetFormSubmissionsAndStatusTotals(options: AdminFormSubmissionsDataOptionsType, loader: (v: boolean) => void) {
    const queryParts: string[] = [];
    if (options.sortField) {
      queryParts.push(`sortField=${encodeURIComponent(options.sortField)}`);
      queryParts.push(`sortDir=${options.sortDirection}`);
    }
    if (options.statuses.length) queryParts.push(`statuses=${options.statuses.join(',')}`);
    if (options.assignedToUuids.length) queryParts.push(`assignedToUuids=${options.assignedToUuids.join(',')}`);
    if (options.tags.length) queryParts.push(`tags=${options.tags.join(',')}`);
    if (options.searchQuery) queryParts.push(`searchQuery=${encodeURIComponent(options.searchQuery)}`);
    if (options.page > 0) queryParts.push(`pageNum=${options.page}`);
    queryParts.push(`pageSize=${options.rowsPerPage}`);
    if (options.dateRange.length && options.dateRange[0] !== '') queryParts.push(`dateRange=${options.dateRange.join(',')}`);

    const query = queryParts.length ? `?${queryParts.join('&')}` : '';

    loader(true);
    let result = await DashboardAPI.callForData({route: `AdminForm/Form/${options.formUuid}/Submissions${query}`}) as {totalCount: number, moreFiltersCounts: any, submissions: any};
    const totalCount = result ? result.totalCount : 0;
    const moreFiltersCounts = result ? result.moreFiltersCounts : {};
    const submissions = result ? result.submissions : [];

    let statusTotals = null;
    if (moreFiltersCounts !== null) {
      // If filters from the "More Filters" dialog are set, use those for the status totals...
      statusTotals = moreFiltersCounts;
    } else {
      // ...otherwise, get the status totals from the API
      statusTotals = await DashboardAPI.callForData({route: `AdminForm/Form/${options.formUuid}/Submissions/StatusTotal`});
    }
    loader(false); 

    return {
      totalCount,
      submissions,
      statusTotals
    };
  }

  async GetFormSubmisisonByUuid(submissionUuid: string, loader: (v: boolean) => void) : Promise<iAdminFormSubmissionsData> {
    loader(true);
    const submission = await DashboardAPI.callForData<iAdminFormSubmissionsData>({route: `AdminForm/Submission/${submissionUuid}`});
    loader(false);

    return submission;
  }

  async DeleteAdminForm(formUuid: string, authId: string | null, loader: (v: boolean) => void) {
    let response = null;

    if (authId !== null) {
      loader(true);
      response = await DashboardAPI.callForData({
        route: `Form/${formUuid}`,
        method: 'DELETE'
      });
      loader(false)
    }

    return response;
  }

  async UpdateSubmissionStatuses(formUuid: string, submissionUuids: string[] | undefined, status: Status, loader: (v: boolean) => void) {
    let response = null;

    loader(true);
    response = await DashboardAPI.call({
      route: `AdminForm/UpdateSubmissionsStatuses`,
      method: 'PATCH',
      body: {
        formUuid: formUuid,
        submissionUuids: submissionUuids,
        status: status
      }
    });
    loader(false)

    return response;
  
  }
  async UpdateSubmisionAssignee(formUuid: string, submissionUuids: string[], assigneeUuid: string, loader: (v: boolean) => void) {
    let response = null;

    loader(true);
    response = await DashboardAPI.call({
      route: `AdminForm/UpdateSubmissionsAssignees`,
      method: 'PATCH',
      body: {
        submissionUuids: submissionUuids,
        assigneeUuid: assigneeUuid
      }
    });
    loader(false)

    return response;
  }

  async GetFormAssignees(formUuid: string, loader: (v: boolean) => void): Promise<any>{
    loader(true);
    const assignees = await DashboardAPI.callForData({route: `AdminForm/Form/${formUuid}/Assignees`});
    loader(false);
    return assignees;
  }

}


const adminFormsService = new AdminFormsService()
export default adminFormsService;