import { useContext} from 'react';
import InstituionUserEdit from '../components/admin/InstitutionUserEdit';
import {  AdminUsersDataContext, AdminUsersDataContextProvider, UserType } from '../contexts/AdminUsersDataContext';
import UserEdit from '../components/admin/UserEdit';


export type AdminUserCrudActionType = 'edit' | 'add'

//We don't have different actions, but I'm just going to keep this here for now.
export type AdminUserCrudProps = {
    action: AdminUserCrudActionType;
    userType: UserType;
};


export default function AdminUserCrud(props: AdminUserCrudProps) {
    const adminUsersDataContext = useContext(AdminUsersDataContext);

    
    return (
        <>
            {adminUsersDataContext.selectedUserType === 'Institution' && <InstituionUserEdit />}
            {adminUsersDataContext.selectedUserType === 'Students' && <UserEdit/>}
        </>
    )
}