import { Dispatch, SetStateAction } from "react";
import { NotificationSendResult } from "../../apis/notifications/models/notification-send-result";
import ConfirmationDialog from "../ConfirmationDialog";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

type props = {
    result: NotificationSendResult;
    notifiedUsers: NotifiedUser[];
    isOpenDialog: boolean;
    setIsOpenDialog: Dispatch<SetStateAction<boolean>>;
};

type NotifiedUser = {
    name: string;
    email?: string;
}

const tableSX = {
    tableHead: {
      fontSize: '0.875rem',
      fontWeight: '500',
      px: 2,
      pt: 0,
      pb: 1.25,
      textTransform: 'uppercase'
    },
    tableCell: {
        color: 'InceptiaGreen.main',
        fontSize: '1rem',
        fontWeight: '500',
        px: 1
    }
  };

const TableWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
      <TableContainer>
        <Table aria-label="Notification table">
          <TableHead>
            <TableRow>
              <TableCell component="th" sx={tableSX.tableHead}>Name</TableCell>
              <TableCell component="th" sx={tableSX.tableHead}>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    );
};

const NotificationInformational = ({ result, notifiedUsers, isOpenDialog, setIsOpenDialog }: props) => {
    return (
        <ConfirmationDialog
            open={isOpenDialog}
            setOpenDialog={setIsOpenDialog}
            ariaPrefix='notifications'
            dialogTitle='Sent Notifications'
            singleButton={true}
            ctaButtonText='Close'
            ctaButtonWorkingText='Closing...'
            cancelButtonText=''>
            <Typography variant='body1'>{result.successful_notifications.length} successful SMS notifications.</Typography>
            <Typography variant='body1' sx={{ mb: 2 }}>{result.failed_notifications.length} failed SMS notifications.</Typography>
            <Typography variant='h6' sx={{ mb: 2 }}>Failed SMS Notifications</Typography>
            <TableWrapper>
            {notifiedUsers.length === 0 && 
            <Typography variant='body1'>No failed SMS notifications.</Typography>
            }
            {notifiedUsers.map(row => (
                <TableRow>
                    <TableCell sx={tableSX.tableCell}>
                        <div>{row.name}</div>
                    </TableCell>
                    <TableCell sx={tableSX.tableCell}>
                        <div>{row.email}</div>
                    </TableCell>
                </TableRow>
            ))}
            </TableWrapper>
        </ConfirmationDialog>
    );
};

export default NotificationInformational;