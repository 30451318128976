import { useState } from 'react';
import Typography from '@mui/material/Typography';
import ProfileForm from './ProfileForm';
import ProfileFormConnections from './ProfileFormConnections';
import ProfileFormSecurity from './ProfileFormSecurity';
import { pageHeaderSX } from '../utilities/CSS';
import ProfileFormCommunication from './ProfileFormCommunication';
import CustomTabs, { CustomTabPanel } from './CustomTabs';
import { iUser } from '../contexts/UserContext';
import { iUserDTO } from '../utilities/APIInterfaces';

export type DashboardCenterSettingsProps = {
  user: iUser | null | undefined;
  updateUser: (user:iUserDTO) => void;
  isAdminEdit: boolean;
}
export default function DashboardCenterSettings(props: DashboardCenterSettingsProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setSelectedTab(newTab);
  };

  return (
    <>
      <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4.5 }}>Settings</Typography>

      <CustomTabs
        ariaTabAndPanelPrefix='settings'
        aria-label='Settings Tabs'
        allowScrollButtonsMobile
        selectedTab={selectedTab}
        variant='scrollable'
        tabs={[
          { label: 'My Account' },
          {label: "Contributors"},
          { label: 'Communication' },
          { label: 'Security' },
        ]}
        handleTabChange={handleTabChange}
      />
      <CustomTabPanel index={0} selectedTab={selectedTab}>
        <ProfileForm isAdminEdit={props.isAdminEdit}  user={props.user} updateUser={props.updateUser}  />
      </CustomTabPanel>
      <CustomTabPanel index={1} selectedTab={selectedTab}>
        <ProfileFormConnections isAdminEdit={props.isAdminEdit} user={props.user} updateUser={props.updateUser} />
      </CustomTabPanel>
      <CustomTabPanel index={2} selectedTab={selectedTab} autoHeight>
        <ProfileFormCommunication isAdminEdit={props.isAdminEdit}  user={props.user} updateUser={props.updateUser}  />
      </CustomTabPanel>
      <CustomTabPanel index={3} selectedTab={selectedTab}>
        <ProfileFormSecurity isAdminEdit={props.isAdminEdit}  user={props.user} updateUser={props.updateUser} />
      </CustomTabPanel>
    </>
  );
}
