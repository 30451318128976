import DashboardAPI from '../apis/DashboardAPI';
import { NotificationData, NotificationScheduleDto, NotificationTemplateDto, NotificationTemplateStatusEnum, PaginatedResultNotificationSchedule, PaginatedResultNotificationTemplate, SearchRequest, SentNotificationDto } from '../apis/notifications';
import { FormTemplateLink } from '../apis/notifications/models/form-template-link';
import { NotificationEmailSender } from '../apis/notifications/models/notification-email-sender';
import { NotificationSendResult } from '../apis/notifications/models/notification-send-result';
import { NotificationTriggerDto } from '../apis/notifications/models/notification-trigger-dto';
import { SentNotificationSearchDto } from '../apis/notifications/models/sent-notification-search-dto';

class NotificationService {
    async SendNotification(body: Array<NotificationData>): Promise<NotificationSendResult> {
        let result = await DashboardAPI.callForData<NotificationSendResult>({
            method: "POST",
            route: "notification/send",
            body: body
        });
        return result;
    }

    async SearchSentNotifications(body: SentNotificationSearchDto): Promise<SentNotificationDto[]> {
        let result = await DashboardAPI.callForData<SentNotificationDto[]>({
            method: "POST",
            route: "notification/sent/search",
            body: body
        });
        return result;
    }

    async SearchTemplates(body: SearchRequest): Promise<NotificationTemplateDto[]> {
        let result = await DashboardAPI.callForData<PaginatedResultNotificationTemplate>({
            method: "POST",
            route: "notification/template/search",
            body: body
        });
        return result.data ?? [];
    }

    async CreateTemplate(body: NotificationTemplateDto): Promise<NotificationTemplateDto> {
        let result = await DashboardAPI.callForData<NotificationTemplateDto>({
            method: "POST",
            route: "notification/template",
            body: body
        });
        return result;
    }

    async UpdateTemplate(id: number, body: NotificationTemplateDto): Promise<NotificationTemplateDto> {
        let result = await DashboardAPI.callForData<NotificationTemplateDto>({
            method: "PATCH",
            route: `notification/template/${id}`,
            body: body
        });
        return result;
    }

    async DeleteTemplate(id: number | undefined, orgId: number | undefined, 
        status: NotificationTemplateStatusEnum | undefined): Promise<void> {

        await DashboardAPI.call({
            method: "DELETE",
            route: `notification/template/${id}?orgId=${orgId}&status=${status}`
        });
    }

    async SearchSchedules(body: SearchRequest): Promise<NotificationScheduleDto[]> {
        let result = await DashboardAPI.callForData<PaginatedResultNotificationSchedule>({
            method: "POST",
            route: "notification/schedule/search",
            body: body
        });
        return result.data ?? [];
    }

    async GetSchedulesByOrgId(orgId: number): Promise<NotificationScheduleDto[]> {
        let result = await DashboardAPI.callForData<NotificationScheduleDto[]>({
            method: "GET",
            route: `notification/schedule/getByOrgId/${orgId}`
        });
        return result;
    }

    async CreateSchedule(body: NotificationScheduleDto): Promise<NotificationScheduleDto> {
        let result = await DashboardAPI.callForData<NotificationScheduleDto>({
            method: "POST",
            route: "notification/schedule",
            body: body
        });
        return result;
    }

    async UpdateSchedule(id: number, body: NotificationScheduleDto): Promise<NotificationScheduleDto> {
        let result = await DashboardAPI.callForData<NotificationScheduleDto>({
            method: "PATCH",
            route: `notification/schedule/${id}`,
            body: body
        });
        return result;
    }

    async DeleteSchedule(id: number | undefined): Promise<void> {
        await DashboardAPI.call({
            method: "DELETE",
            route: `notification/schedule/${id}`
        });
    }

    async GetAllTemplates(): Promise<NotificationTemplateDto[]> {
        let result = await DashboardAPI.callForData<NotificationTemplateDto[]>({
            method: "GET",
            route: `notification/template`
        });
        return result;
    }

    async GetTemplatesByOrgId(orgId: number): Promise<NotificationTemplateDto[]> {
        let result = await DashboardAPI.callForData<NotificationTemplateDto[]>({
            method: "GET",
            route: `notification/template/getByOrgId/${orgId}`
        });
        return result;
    }

    async GetOrgIdsForTemplate(templateId: number): Promise<number[]> {
        let result = await DashboardAPI.callForData<number[]>({
            method: "GET",
            route: `notification/template/getOrgIds/${templateId}`
        });
        return result;
    }

    async UpdateOrgIdsForTemplate(templateId: number, orgIds: number[]): Promise<boolean> {
        let result = await DashboardAPI.callForData<boolean>({
            method: "PATCH",
            route: `notification/template/updateOrgIds/${templateId}`,
            body: orgIds
        });
        return result;
    }

    async GetAllEmailSenders(): Promise<NotificationEmailSender[]> {
        let result = await DashboardAPI.callForData<NotificationEmailSender[]>({
            method: "GET",
            route: "notification/email_sender"
        });
        return result;
    }

    async GetFormTemplateLinks(formUUID: string): Promise<FormTemplateLink[]> {
        let result = await DashboardAPI.callForData<FormTemplateLink[]>({
            method: "GET",
            route: `notification/form_template_link/${formUUID}`
        });
        return result;
    }

    async DeleteFormTemplateLink(id: number): Promise<number> {
        let result = await DashboardAPI.callForData<number>({
            method: "DELETE",
            route: `notification/form_template_link/${id}`
        });
        return result;
    }

    async AddFormTemplateLinks(formUUID: string, templateIds: (number | undefined)[]): Promise<number> {
        let result = await DashboardAPI.callForData<number>({
            method: "POST",
            route: `notification/form_template_link/${formUUID}`,
            body: templateIds
        });
        return result;
    }

    async GetTriggersByOrgId(orgId: number): Promise<NotificationTriggerDto[]> {
        let result = await DashboardAPI.callForData<FormTemplateLink[]>({
            method: "GET",
            route: `notification/trigger/byOrgId/${orgId}`
        });
        return result;
    }

    async DeleteTrigger(id: number): Promise<number> {
        let result = await DashboardAPI.callForData<number>({
            method: "DELETE",
            route: `notification/trigger/${id}`
        });
        return result;
    }

    async CreateTrigger(trigger: NotificationTriggerDto): Promise<NotificationTriggerDto> {
        let result = await DashboardAPI.callForData<NotificationTriggerDto>({
            method: "POST",
            route: `notification/trigger`,
            body: trigger
        });
        return result;
    }

    async UpdateTrigger(trigger: NotificationTriggerDto): Promise<NotificationTriggerDto> {
        let result = await DashboardAPI.callForData<NotificationTriggerDto>({
            method: "PATCH",
            route: `notification/trigger`,
            body: trigger
        });
        return result;
    }
}

const notificationService = new NotificationService()
export default notificationService;