import { useContext, useState, useEffect } from 'react';
import { AdminUsersDataContext } from '../../contexts/AdminUsersDataContext';import UserService from '../../services/UserService';
import { iUser } from '../../contexts/UserContext';
import userService from '../../services/UserService';
import DashboardCenterSettings from '../DashboardCenterSettings';
import { BackToUsersLink } from  '../AdminCenterUsers';

export default function  UserEdit(){
    const [user,setUser] = useState<iUser | null | undefined>(null);
    const adminUsersDataContext = useContext(AdminUsersDataContext);useEffect(() => {
        const fetchUser = async () => {
            if(adminUsersDataContext.studentUser?.authId){
                await userService.GetUser(adminUsersDataContext.studentUser?.authId, (v) => { }, true).then((result) => {
                    
                    setUser(userService.MapToUser(result));
                })
            }
        }
        fetchUser();
         
     }, [adminUsersDataContext.studentUser, adminUsersDataContext.isStudentUsersDataLoaded]);
    return (
        <div>
            <BackToUsersLink />
            {adminUsersDataContext.studentUser && <DashboardCenterSettings isAdminEdit={true} user={user} updateUser={(user) => { setUser(userService.MapToUser(user)) }} />}
        </div>
    )
}