import { NotificationTriggerDto } from "../../apis/notifications/models/notification-trigger-dto";
import * as yup from 'yup';
import ManageCrudPage, { DropdownStyles } from "./ManageCrudPage";
import notificationService from "../../services/NotificationService";
import { NotificationCommunicationTypeEnum, NotificationTemplateDto, NotificationTemplateStatusEnum } from "../../apis/notifications";
import { useCallback, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, TableCell, Typography } from "@mui/material";
import { StatusLabel } from "../../utilities/FormEnums";

interface NotificationTriggerCrudProps {
    orgId?: number;
    isInceptiaUser: boolean;
    templates: NotificationTemplateDto[];
};

const NotificationTriggerCrud = ({ orgId, isInceptiaUser, templates }: NotificationTriggerCrudProps) => {
    const [triggers, setTriggers] = useState<NotificationTriggerDto[]>([]);

    const initialValues: NotificationTriggerDto = {
        org_id: orgId,
        status_id: undefined,
        notification_template_id: undefined
    };

    const validationSchema = yup.object().shape({
        status_id: yup.number().required('Status is required'),
        notification_template_id: yup.number().required('Template is required')
    });

    const fetchItems = useCallback(async () => {
        let triggs: NotificationTriggerDto[] = [];
        if (orgId) {
            triggs = await notificationService.GetTriggersByOrgId(orgId) ?? [];
            triggs = triggs.sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
            setTriggers(triggs);
        }
        return triggs;
    }, [orgId]);

    let getSelectableTemplates = (values: NotificationTriggerDto): NotificationTemplateDto[] => {
        let temps = templates ?? [];
        let triggs = triggers ?? [];
        temps = temps.filter(t => (t.org_id === orgId && t.status === NotificationTemplateStatusEnum.Approved)
            || t.id === values.notification_template_id);
        triggs = triggs.filter(t => t.notification_template_id !== values.notification_template_id);
        return [{
            id: undefined,
            name: " - None - ",
            communication_type: NotificationCommunicationTypeEnum.Email,
            org_id: orgId ?? 0
        }, ...temps.filter(t => !triggs.some(s => s.notification_template_id === t.id))];
    };

    const renderTableHeaders = () => (
        <>
            <TableCell>Template Name (* = Default)</TableCell>
            <TableCell>Form Status</TableCell>
        </>
    );

    const renderTableColumns = (item: NotificationTriggerDto) => {
        const templateName = templates?.find(t => t.id === item.notification_template_id)?.name || '';

        return (<>
            <TableCell>{item.org_id !== orgId && "*"}{templateName}</TableCell>
            <TableCell>{item.status_id ? StatusLabel.get(item.status_id) : ''}</TableCell>
        </>);
    };

    const renderFormFields = (formik: any, isCreate: boolean) => {
        let readOnly: boolean = !isInceptiaUser || (!isCreate && formik.values.org_id !== orgId);
        let selectableTemplates: NotificationTemplateDto[] = getSelectableTemplates(formik.values);

        return (<>
            <Grid container rowGap={4} columnSpacing={3.5} sx={{ mb: 6.25 }}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth error={formik.touched.notification_template_id && Boolean(formik.errors.notification_template_id)} sx={DropdownStyles}>
                        <InputLabel id="template-label">Template</InputLabel>
                        <Select
                            labelId="template-label"
                            id="notification_template_id"
                            name="notification_template_id"
                            label="Template"
                            value={formik.values.notification_template_id}
                            onChange={formik.handleChange}
                            disabled={readOnly ? true : undefined}
                        >
                            {selectableTemplates.map((template) => (
                                <MenuItem key={template?.id} value={template?.id}>
                                    {template?.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.touched.notification_template_id && formik.errors.notification_template_id ? (
                            <Typography variant="caption" color="error">{String(formik.errors.notification_template_id)}</Typography>
                        ) : null}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth error={formik.touched.status_id && Boolean(formik.errors.status_id)} sx={DropdownStyles}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status_id"
                            name="status_id"
                            label="Status"
                            value={formik.values.status_id}
                            onChange={formik.handleChange}
                            disabled={readOnly ? true : undefined}
                        >
                            {[...Array.from(StatusLabel).filter(s => s?.[0]), [undefined, "None"]].map((status) => (
                                <MenuItem key={status?.[0] ?? undefined} value={status?.[0] ?? undefined}>
                                    {status?.[1]}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.touched.status_id && formik.errors.status_id ? (
                            <Typography variant="caption" color="error">{String(formik.errors.status_id)}</Typography>
                        ) : null}
                    </FormControl>
                </Grid>
            </Grid>
        </>);
    }

    return (
        <>
            <ManageCrudPage<NotificationTriggerDto>
                title="Manage Notification Triggers"
                entityName="Trigger"
                initialValues={initialValues}
                validationSchema={validationSchema}
                fetchItems={fetchItems}
                isDefault={s => s.org_id !== orgId || !isInceptiaUser}
                createItem={async (trigger: NotificationTriggerDto) => {
                    await notificationService.CreateTrigger({ ...trigger, org_id: orgId });
                }}
                updateItem={async (id: number, trigger: NotificationTriggerDto) => {
                    await notificationService.UpdateTrigger({ ...trigger, id: id });
                }}
                deleteItem={async trigger => {
                    await notificationService.DeleteTrigger(trigger?.id ?? 0);
                }}
                renderFormFields={renderFormFields}
                renderTableColumns={renderTableColumns}
                renderTableHeaders={renderTableHeaders}
                canAddNew={isInceptiaUser}
            />
        </>
    );
};

export default NotificationTriggerCrud;