import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link, Paper, Skeleton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AdminFormDataContext, iAdminFormData } from '../contexts/AdminFormDataContext';
import { type AdminFormSubmissionsFilterType, type AdminFormSubmissionsDataOptionsType, AdminFormSubmissionsDataContext, SetDataOptionsToLocalStorage } from '../contexts/AdminFormSubmissionsDataContext';
import { AdminSearchFormsAndStudents } from './AdminCenterHome';
import AdminFormSubmissionsTable from './AdminFormSubmissionsTable';
import { cardSX, pageHeaderSX } from '../utilities/CSS';
import { Status, StatusLabel, VerifierType, VerifierTypeNumber } from '../utilities/FormEnums';
import KeycloakService from '../services/KeycloakService';

const adminCenterFormSX = {
  toggleButtonGroup: {
    flexWrap: 'wrap',
    gap: 1,
    mb: 2,
    '& .MuiToggleButtonGroup-firstButton, & .MuiToggleButtonGroup-middleButton, & .MuiToggleButtonGroup-lastButton': {
      backgroundColor: '#fff',
      border: '1px solid transparent',
      borderRadius: '100vw',
      boxShadow: '0 1px 2px 2px rgba(207, 216, 220, 0.32)', // Copied from Figma
      color: 'grey.800',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      ml: 0
    }
  },
  toggleButton: {
    px: 1,
    height: '36px',
    textTransform: 'none',
    transition: 'all 0.25s',
    '&.Mui-disabled': {
      backgroundColor: 'grey.100',
      border: '1px solid transparent',
      color: 'grey.400'
    },
    '&.Mui-disabled.Mui-selected': {
      backgroundColor: 'rgb(0 109 63 / 0.25)', // Still want to see which buttons are selected, even if disabled
      color: 'InceptiaGreen.contrastText'
    },
    '&:not(.Mui-disabled):hover, &:not(.Mui-disabled).Mui-selected, &:not(.Mui-disabled).Mui-selected:hover': {
      backgroundColor: 'InceptiaGreen.main',
      color: 'InceptiaGreen.contrastText'
    },
    '& .MuiTouchRipple-child': {
      color: 'InceptiaGreen.main'
    },
    '&.Mui-selected .MuiTouchRipple-child': {
      color: 'white'
    }
  }
};

export const BackToFormsLink = () => {
  const navigate = useNavigate();

  return (
    <Link
      tabIndex={0} // Need this to get it into the tab order since it doesn't have an href
      color='inherit'
      onClick={() => navigate('/admin/forms')}
      onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
        // Keyboard accessibility - "click" link when pressing enter or space
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          navigate('/admin/forms');
        }
      }}
      underline='hover'
      sx={{
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        mt: 0.5,
        mb: 1.25
      }}
    >
      <ChevronLeftIcon fontSize='small' sx={{ ml: '-0.25rem', mr: 0.25 }} />
      <Typography variant='body2'>Back to {process.env.REACT_APP_TITLE}</Typography>
    </Link>
  );
};

type FilterButtonsProps = {
  adminFormSubmissionsStatusTotals: {
    [key: string]: number
  };
  isAdminFormSubmissionsDataLoaded: boolean;
  searchQuery: string;
  selectedFilters: AdminFormSubmissionsFilterType[];
  handleFilterChange: (e: React.MouseEvent<HTMLElement, MouseEvent>, value: AdminFormSubmissionsFilterType[]) => void;
}
const FilterButtons = ({ adminFormSubmissionsStatusTotals, isAdminFormSubmissionsDataLoaded, searchQuery, selectedFilters, handleFilterChange }: FilterButtonsProps) => {
  return (
    <ToggleButtonGroup
      aria-label='Form submissions filter'
      disabled={searchQuery.length > 0}
      size='small'
      value={selectedFilters}
      onChange={handleFilterChange}
      sx={adminCenterFormSX.toggleButtonGroup}
    >
      <ToggleButton
        aria-label='All filter button'
        value='All'
        sx={adminCenterFormSX.toggleButton}
      >All</ToggleButton>

      {/*
        The order of the buttons in the Figma screens doesn't match the order of the Status enum.
        And the text on one of the buttons (In-Review) doesn't match the StatusLabel enum.
        And two of the statuses are not in the list of Figma filter buttons (Removed [originally Suspended], Suspended [new]).
      */}
      <ToggleButton
        aria-label={`${StatusLabel.get(Status.SUBMITTED)} filter button`}
        value={Status.SUBMITTED}
        sx={adminCenterFormSX.toggleButton}
      >{StatusLabel.get(Status.SUBMITTED)} (
        {!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.submitted?.toLocaleString() || 0}
      )</ToggleButton>

      <ToggleButton
        aria-label={`${StatusLabel.get(Status.APPROVED)} filter button`}
        value={Status.APPROVED}
        sx={adminCenterFormSX.toggleButton}
      >{StatusLabel.get(Status.APPROVED)} (
        {!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.approved?.toLocaleString() || 0}
      )</ToggleButton>

      <ToggleButton
        aria-label={`${StatusLabel.get(Status.IN_REVIEW)} filter button`}
        value={Status.IN_REVIEW}
        sx={adminCenterFormSX.toggleButton}
      >In-Review ({!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.in_Review?.toLocaleString() || 0})</ToggleButton>

      <ToggleButton
        aria-label={`${StatusLabel.get(Status.INELIGIBLE)} filter button`}
        value={Status.INELIGIBLE}
        sx={adminCenterFormSX.toggleButton}
      >{StatusLabel.get(Status.INELIGIBLE)} (
        {!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.ineligible?.toLocaleString() || 0}
      )</ToggleButton>

      <ToggleButton
        aria-label={`${StatusLabel.get(Status.DECLINED)} filter button`}
        value={Status.DECLINED}
        sx={adminCenterFormSX.toggleButton}
      >{StatusLabel.get(Status.DECLINED)} (
        {!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.declined?.toLocaleString() || 0}
      )</ToggleButton>

      <ToggleButton
        aria-label={`${StatusLabel.get(Status.ACTION_NEEDED)} filter button`}
        value={Status.ACTION_NEEDED}
        sx={adminCenterFormSX.toggleButton}
      >{StatusLabel.get(Status.ACTION_NEEDED)} (
        {!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.action_Needed?.toLocaleString() || 0}
      )</ToggleButton>

      <ToggleButton
        aria-label={`${StatusLabel.get(Status.INCOMPLETE)} filter button`}
        value={Status.INCOMPLETE}
        sx={adminCenterFormSX.toggleButton}
      >{StatusLabel.get(Status.INCOMPLETE)} (
        {!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.incomplete?.toLocaleString() || 0}
      )</ToggleButton>

      <ToggleButton
        aria-label={`${StatusLabel.get(Status.SUSPENDED)} filter button`}
        value={Status.SUSPENDED}
        sx={adminCenterFormSX.toggleButton}
      >{StatusLabel.get(Status.SUSPENDED)} (
        {!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.suspended?.toLocaleString() || 0}
      )</ToggleButton>

      <ToggleButton
        aria-label={`${StatusLabel.get(Status.PENDING_FINAL_REVIEW)} filter button`}
        value={Status.PENDING_FINAL_REVIEW}
        sx={adminCenterFormSX.toggleButton}
      >{StatusLabel.get(Status.PENDING_FINAL_REVIEW)} (
        {!isAdminFormSubmissionsDataLoaded ? '...' : adminFormSubmissionsStatusTotals?.pending_Final_Review?.toLocaleString() || 0}
      )</ToggleButton>

    </ToggleButtonGroup>
  );
};

export default function AdminCenterForm() {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<AdminFormSubmissionsFilterType[]>(['All']);
  const { formsData, isAdminFormDataLoaded } = useContext(AdminFormDataContext);
  const { formUuid } = useParams();
  const { adminFormSubmissionsStatusTotals, isAdminFormSubmissionsDataLoaded, setIsAdminFormSubmissionsDataLoaded,
    adminFormSubmissionsDataOptions, setAdminFormSubmissionsDataOptions, setLoadFromLocalStorage } = useContext(AdminFormSubmissionsDataContext);

  const selectedForm: iAdminFormData | undefined = (formUuid) ? formsData?.find(formItem => {return formItem.uuid === formUuid}) : undefined;

  const [disableChangeOptions, setDisableChangeOptions] = useState(false);
  const verifier_Type_Id = VerifierTypeNumber.get(selectedForm?.verification || '') || 0;
  const keycloakInceptiaGroups = ['Institution', 'InstitutionAdmin'];
  const keycloakInstitutionGroups = ['Manager', 'SrOperations', 'Operations'];
  
  useEffect(() => {
    // If the form verifier type = 'Inceptia' and the user's keycloak group is in the provided groups...
    if ((verifier_Type_Id === VerifierType.INCEPTIA && keycloakInceptiaGroups.some(group => KeycloakService.hasGroup([group]))) 
      || (verifier_Type_Id === VerifierType.INSTITUTION && keycloakInstitutionGroups.some(group => KeycloakService.hasGroup([group])))) {
      // ...then disable the change menu options (Change Status, Assignee, and Tags) under the 'Actions' button
      // And the Autocompletes for changing Assignee, Tags, and Status on the "Applicant Details" page
      setDisableChangeOptions(true);
    }
  }, [selectedForm?.verification]);

  useEffect(() => {
    let updatedSearchQuery: string | null = searchQuery;

    if (localStorage.getItem("searchQuery") === null) {
      updatedSearchQuery = "";
      setSearchQuery(updatedSearchQuery);
    } else if (searchQuery === null) {
      updatedSearchQuery = localStorage.getItem("searchQuery") ? localStorage.getItem("searchQuery")! : "";
      setSearchQuery(updatedSearchQuery);
    } else {
      updatedSearchQuery = searchQuery;
    }

    // Will cause the "loading" state to display
    setIsAdminFormSubmissionsDataLoaded(false);

    const delayedSearch = setTimeout(() => {
      const newAdminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType = {
        ...adminFormSubmissionsDataOptions,
        searchQuery: updatedSearchQuery
      };

      setAdminFormSubmissionsDataOptions(newAdminFormSubmissionsDataOptions);
      SetDataOptionsToLocalStorage(newAdminFormSubmissionsDataOptions);
    }, 250);

    return () => clearTimeout(delayedSearch);
  }, [searchQuery]);

  const handleFilterChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: AdminFormSubmissionsFilterType[]) => {
    const allInSelectedFilters = selectedFilters.includes('All');
    const allInValue = value.includes('All');
    const newAdminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType = {...adminFormSubmissionsDataOptions};
    let newValue: AdminFormSubmissionsFilterType[] = [];

    // Deselect the 'All' filter if another filter is selected
    if (allInValue && allInSelectedFilters && value.length > 1) newValue = value.filter((item) => item !== 'All');

    // If 'All' filter is clicked, deselect all other filters
    if (allInValue && ! allInSelectedFilters && value.length > 1) newValue = ['All'];

    // If all non-'All' filters are deselected, select 'All' (either 'All' or some combination of other filters will always be selected)
    if (value.length === 0) newValue = ['All'];

    if (newValue.length > 0) {
      setSelectedFilters(newValue);
      if (newValue.includes('All')) {
        newAdminFormSubmissionsDataOptions.statuses = [];
      } else {
        newAdminFormSubmissionsDataOptions.statuses = newValue;
      }
    } else {
      // Otherwise, select the clicked filter
      setSelectedFilters(value);
      newAdminFormSubmissionsDataOptions.statuses = value;
    }

    setAdminFormSubmissionsDataOptions(newAdminFormSubmissionsDataOptions);
    SetDataOptionsToLocalStorage(newAdminFormSubmissionsDataOptions);
    setLoadFromLocalStorage(true);

    setIsAdminFormSubmissionsDataLoaded(false);
  };

  return (
    <>
      {/* Loading... */}
      { ! isAdminFormDataLoaded && (
        <>
          {/* < Back to [process.env.REACT_APP_TITLE] */}
          <Skeleton animation='pulse' variant='text' sx={{ height: '20px', mt: 0.5, mb: 1.25, transform: 'none' }} width={260} />

          {/* Institution - Form Name */}
          <Skeleton animation='pulse' variant='text' sx={{ height: '37px', mb: 2.25, transform: 'none' }} width={400} />
        </>
      )}

      {/* Loaded, but no forms in formData array - shouldn't happen, right? */}
      {isAdminFormDataLoaded && (formsData && formsData.length === 0) && (
        <>
          <BackToFormsLink />
          <Typography variant='h1' color='error.main' sx={{ ...pageHeaderSX, mb: 2.25 }}>Selected form NOT found</Typography>
        </>
      )}

      {/* Loaded, with forms in the formData array */}
      {isAdminFormDataLoaded && (formsData && formsData.length > 0) && (
        <>
          <BackToFormsLink />
          <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 2.25 }}>
            {selectedForm?.institution} - {selectedForm?.name}
          </Typography>
        </>
      )}

      <Paper sx={{ ...cardSX.paper, mb: 3.75 }}>
        <AdminSearchFormsAndStudents
          placeholderText='Search submissions'
          searchQuery={searchQuery ? searchQuery : ""}
          setSearchQuery={setSearchQuery}
        />
      </Paper>

      <FilterButtons
        adminFormSubmissionsStatusTotals={adminFormSubmissionsStatusTotals}
        isAdminFormSubmissionsDataLoaded={isAdminFormSubmissionsDataLoaded}
        searchQuery={adminFormSubmissionsDataOptions.searchQuery}
        selectedFilters={adminFormSubmissionsDataOptions.statuses}
        handleFilterChange={handleFilterChange}
      />

      <Paper sx={cardSX.paper}>
        <AdminFormSubmissionsTable disableChangeOptions={disableChangeOptions} searchQuery={searchQuery ? searchQuery : ""} />
      </Paper>
    </>
  );
}
