import Container from '@mui/material/Container/Container';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch/Switch';
import {  useState } from 'react';
import { iUser } from '../contexts/UserContext';
import styled from '@emotion/styled';
import React from 'react';
import { Box, Button, FormControl, Link, Radio, RadioGroup } from '@mui/material';
import userService from '../services/UserService';
import { useSnackbar } from 'notistack';
import { iUserDTO } from '../utilities/APIInterfaces';

const GreenToggleSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#006D3F',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
    },
  }));
  export type ProfileFormCommunicationProps = {
    user: iUser | null | undefined;
    updateUser: (user:iUserDTO) => void;
    isAdminEdit: boolean;
  }
  export default function ProfileFormCommunication(props: ProfileFormCommunicationProps) {

    const [ smsConsent, setSmsConsent ] = useState(props.user?.smsConsent ?? true);
    const { enqueueSnackbar } = useSnackbar();
  
    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
      const oldUser = userService.MapToDto(props.user);
      props.user!.smsConsent = smsConsent;

      await userService.UpdateUser(oldUser, userService.MapToDto(props.user), (x) => x)
        .then(() => enqueueSnackbar('Consent successfully changed', {variant:"success"}))
        .catch(enqueueSnackbar);
    }

    return (
      <Container sx={{ p: { xs: 2.25, sm: 4.5 } }}>

        <Typography variant='h2' id='smsConsentLabel' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4 }}>Text Messaging Opt In</Typography>

        <FormControl sx={{ mb: 2 }}>
          <RadioGroup
            aria-labelledby='smsConsentLabel'
            name='smsConsent'
            value={smsConsent}
          >
            <FormControlLabel
              value={true}
              onChange={() => setSmsConsent(true)}
              control={<Radio />}
              label={
                // Figma width on the text is 510px - which forces the text to wrap
                <Typography variant='body2' sx={{ maxWidth: '510px' }}>
                  I authorize Inceptia to contact me via text message. Carrier message and data rates may apply. <b>(Recommended)</b>
                </Typography>
              }
              sx={{ mr: 0 }}
            />
            <FormControlLabel
              value={false}
              onChange={() => setSmsConsent(false)}
              control={<Radio />}
              label={<Typography variant='body2'>Decline to authorize text messaging, contact me via email</Typography>}
              sx={{ mr: 0 }}
            />
          </RadioGroup>
        </FormControl>

        <Typography variant='body2' sx={{ mb: 4.25 }}>
          View Inceptia's
          <Link
            href='https://www.inceptia.org/privacy/#sms'
            target='_blank'
            sx={{
              color: 'var(--link-color)',
              fontWeight: 'bold',
              ml: 0.5,
              textDecoration: 'none',
              '&:hover, &:focus': {
                textDecoration: 'underline'
              }
            }}
          >SMS Terms of Service</Link>
        </Typography>

        {/*
          Figma has 43px between the bottom of the button and the bottom of the card.
          Card has 36px of padding, and adding 8px to the bottom of the Box holding the button makes it 44px (36px + 8px) - close enough
        */}
        <Box sx={{ mb: 1 }}>
          <Button
            variant='contained'
            size='large'
            sx={{
              bgcolor: 'InceptiaGreen.main',
              fontSize: '1rem',
              px: 5,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'InceptiaGreen.dark',
              }
            }}
            onClick={handleSaveClick}
          >Save Changes</Button>
        </Box>

      </Container>
    );
  }
  