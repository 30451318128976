import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Alert, CircularProgress, Grid, TextField, Typography, TableCell, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as yup from 'yup';
import notificationService from '../../services/NotificationService';
import { ApplicationTypeEnum, NotificationTemplateStatusEnum, NotificationCommunicationTypeEnum, NotificationTemplateDto, NotificationData, CommunicationTypeEnum } from '../../apis/notifications';
import ManageCrudPage, { DropdownStyles } from './ManageCrudPage';
import TemplateLinkForm from './TemplateLinkForm';
import { NotificationEmailSender } from '../../apis/notifications/models/notification-email-sender';
import { UserContext } from '../../contexts/UserContext';
import { enqueueSnackbar } from 'notistack';
import { Editor } from '@tinymce/tinymce-react';
import ConfirmationDialog from '../ConfirmationDialog';

interface NotificationTemplateCrudProps {
    orgId: number | undefined;
    opeid: string | undefined;
    isInceptiaUser: boolean;
    isInceptiaOrganization: boolean;
    isLinkDialogOpen: boolean;
    setIsLinkDialogOpen: Dispatch<SetStateAction<boolean>>;
    fetchTemplates: () => Promise<NotificationTemplateDto[]>;
}

const NotificationTemplateCrud = ({ 
    isLinkDialogOpen, 
    setIsLinkDialogOpen, 
    fetchTemplates, 
    orgId = 0, 
    isInceptiaUser = false, 
    isInceptiaOrganization = false, 
    opeid = undefined 
}: NotificationTemplateCrudProps) => {

    const [templateToLink, setTemplateToLink] = useState<NotificationTemplateDto | undefined>(undefined);
    const [emailSenders, setEmailSenders] = useState<NotificationEmailSender[]>([]);
    const { user } = useContext(UserContext);

    const editorRef = useRef<Editor | null>(null);
    const editorContainerRef = useRef<HTMLElement | null>(null);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [formik, setFormik] = useState<any>();

    type PendingApprovalDialogOptionsType = {
        isOpen: boolean;
        adding: boolean;
        name: string;
        communicationType: NotificationCommunicationTypeEnum;
    };
    const [pendingApprovalDialogOptions, setPendingApprovalDialogOptions] = useState({} as PendingApprovalDialogOptionsType);

    useEffect(() => {
        let getSenders = async () => {
            let senders = await notificationService.GetAllEmailSenders();
            setEmailSenders(senders);
        };
        getSenders();
    }, []);

    const initialValues: NotificationTemplateDto = {
        name: '',
        communication_type: NotificationCommunicationTypeEnum.Email,
        application_type: ApplicationTypeEnum.FormsApp,
        org_id: orgId,
        status: NotificationTemplateStatusEnum.Approved,
        email_sender_id: undefined,
        subject: '',
        body: ''
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        communication_type: yup.string().oneOf(Object.values(NotificationCommunicationTypeEnum)).required(),
        subject: yup.string().notRequired(),
        body: yup.string().required('Body is required'),
        email_sender_id: yup.number().when("communication_type", (communication_type, schema) => {
            if (communication_type.length === 1 &&
                communication_type[0] === NotificationCommunicationTypeEnum.Email.toString())
                return schema.required("Email From is required");
            else
                return schema.notRequired();
        })
    });

    const renderFormFields = (formik: any, isCreate: boolean) => {
        let readOnly: boolean = (!isCreate && formik.values.org_id !== orgId) ||
            (isInceptiaOrganization && !isInceptiaUser);

        return (<>
            <Grid container rowGap={4} columnSpacing={3.5} sx={{ mb: 1 }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="name"
                        label="Name"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        inputProps={{
                            readOnly: readOnly
                        }}
                        InputProps={{
                            className: readOnly ? "Mui-disabled" : undefined
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth error={formik.touched.communication_type && Boolean(formik.errors.communication_type)} sx={DropdownStyles}>
                        <InputLabel id="communication-type-label">Communication Type</InputLabel>
                        <Select
                            labelId="communication-type-label"
                            id="communication_type"
                            name="communication_type"
                            label="Communication Type"
                            value={formik.values.communication_type}
                            onChange={(e) => {
                                formik.setFieldValue('communication_type', e.target.value);

                                if (e.target.value === NotificationCommunicationTypeEnum.SMS && editorRef.current) {
                                    // @ts-ignore
                                    const content = editorRef.current.getContent() || '';

                                    // Strip any HTML tags from the TinyMCE content
                                    if (content) formik.setFieldValue('body', content.replace(/(<([^>]+)>)/gi, ''));
                                }
                            }}
                            disabled={readOnly ? true : undefined}
                        >
                            {Object.values(NotificationCommunicationTypeEnum).map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.touched.communication_type && formik.errors.communication_type ? (
                            <Typography variant="caption" color="error">{String(formik.errors.communication_type)}</Typography>
                        ) : null}
                    </FormControl>
                </Grid>

                {formik.values.communication_type === NotificationCommunicationTypeEnum.Email && (
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth error={formik.touched.email_sender_id && Boolean(formik.errors.email_sender_id)} sx={DropdownStyles}>
                            <InputLabel id="email-sender-label">Email From</InputLabel>
                            <Select
                                labelId="email-sender-label"
                                id="email_sender_id"
                                name="email_sender_id"
                                label="Email From"
                                value={formik.values.email_sender_id}
                                onChange={formik.handleChange}
                                disabled={readOnly ? true : undefined}
                            >
                                {emailSenders && emailSenders.map((sender) => (
                                    <MenuItem key={sender.id} value={sender.id}>
                                        {sender.address}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.email_sender_id && formik.errors.email_sender_id ? (
                                <Typography variant="caption" color="error" sx={{ mt: '3px', ml: '14px' }}>{String(formik.errors.email_sender_id)}</Typography>
                            ) : null}
                        </FormControl>
                    </Grid>
                )}

                {formik.values.communication_type === NotificationCommunicationTypeEnum.Email && (
                    <Grid item xs={12}>
                        <TextField
                            id="subject"
                            label="Subject"
                            fullWidth
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                            helperText={formik.touched.subject && formik.errors.subject}
                            inputProps={{
                                readOnly: readOnly
                            }}
                            InputProps={{
                                className: readOnly ? "Mui-disabled" : undefined
                            }}
                            sx={{ mb: 4 }}
                        />

                        {!editorLoaded && <Typography variant='h5' sx={{ fontWeight: 500 }}>
                            <CircularProgress size={20} sx={{ mr: 1 }} color='inherit' />
                            Editor Loading...
                        </Typography>}
                        <Editor
                            // https://www.tiny.cloud/docs/tinymce/latest/react-ref/#using-the-tinymce-react-component-as-a-uncontrolled-component
                            // The TinyMCE React component is designed to be used as an uncontrolled component, which allows the editor to perform well on larger documents.
                            onInit={(evt, editor: any) => {
                                // Turn off the loader...
                                setEditorLoaded(true);

                                editorRef.current = editor as Editor;
                                editorContainerRef.current = editor.getContainer();
                            }}
                            initialValue={formik.values.body}

                            disabled={readOnly}
                            licenseKey='gpl'
                            tinymceScriptSrc={process.env.REACT_APP_TINYMCE_URL}
                            init={{
                                height: 500,
                                min_height: 250,

                                // https://www.tiny.cloud/docs/tinymce/latest/editor-premium-upgrade-promotion/#promotion
                                // Turn off the upgrade button displayed in the top right of the menu bar
                                promotion: false,

                                // https://www.tiny.cloud/docs/tinymce/latest/content-filtering/#forced_root_block
                                // Force TinyMCE to write <p> tags with an inline style to reduce spacing in the received email
                                // forced_root_block_attrs: {
                                //   'style': 'margin: 0;'
                                // },

                                plugins: 'anchor autolink charmap code emoticons ' +
                                    'help insertdatetime link lists advlist preview quickbars searchreplace ' +
                                    'table visualblocks wordcount',

                                // https://www.tiny.cloud/docs/tinymce/latest/insertdatetime/#insertdatetime_formats
                                insertdatetime_formats: ['%D', '%r', '%Y-%m-%d', '%H:%M:%S', '%D %r'],

                                // https://www.tiny.cloud/docs/tinymce/latest/link/#link_context_toolbar
                                link_context_toolbar: true,

                                // https://www.tiny.cloud/docs/tinymce/latest/quickbars/
                                quickbars_insert_toolbar: false,
                                quickbars_selection_toolbar: 'bold italic backcolor forecolor | blocks | quicklink blockquote',

                                toolbar: 'undo redo | blocks fontsize | ' +
                                    'bold italic backcolor forecolor removeformat | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist outdent indent'
                            }}
                        />
                        {formik.touched.body && Boolean(formik.errors.body) && (<>
                            <Typography color='error' variant='caption' sx={{ mt: 1, ml: 2 }}>{formik.errors.body}</Typography>
                        </>)}
                    </Grid>
                )}

                {formik.values.communication_type === NotificationCommunicationTypeEnum.SMS && (
                    <Grid item xs={12}>
                        <TextField
                            id="body"
                            label="Body"
                            fullWidth
                            multiline
                            rows={4}
                            value={formik.values.body}
                            onChange={formik.handleChange}
                            error={formik.touched.body && Boolean(formik.errors.body)}
                            helperText={formik.touched.body && formik.errors.body}
                            inputProps={{
                                readOnly: readOnly
                            }}
                            InputProps={{
                                className: readOnly ? "Mui-disabled" : undefined
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </>);
    };

    const renderTableHeaders = () => (
        <>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Communication Type</TableCell>
        </>
    );

    const renderTableColumns = (item: NotificationTemplateDto) => (
        <>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.status}</TableCell>
            <TableCell>{(!isInceptiaOrganization && item.org_id === orgId ? "" : "Default ") + item.communication_type}</TableCell>
        </>
    );

    const handleSubmitButtonClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, formik: any, submitForm: boolean) => {

        // Validate the editor's content IF the communication type is email
        if (formik.values.communication_type === NotificationCommunicationTypeEnum.Email) {
            // Need valid refs to the editor/container
            if (!editorRef.current || !editorContainerRef.current) return;

            // @ts-ignore - Typescript doesn't believe that the editor has a getContent() method
            const content = editorRef.current.getContent();
            await formik.setFieldValue('body', content);
            await formik.setTouched('body', true);
            if (content) {
                editorContainerRef.current.style.borderColor = '#eee';
            } else {
                editorContainerRef.current.style.borderColor = '#d32f2f';
                // No content in the editor/body - formik validation will stop the form from submitting
                return;
            }
        }

        // If we're not submitting the form (i.e. saving the template), we're done
        // Doing this since the "Send me a sample" button calls this event handler as well...
        // ...and it doesn't care about showing the pending approval dialog or saving the template.
        if (!submitForm) return;

        // Determine if pending approval confirmation dialog should be displayed
        let showDialog = false;
        if (formik.values.id) {
            // Editing template: if the status is Approved, display the confirmation dialog if NOT isInceptiaUser
            if (formik.values.status === NotificationTemplateStatusEnum.Approved && !isInceptiaUser) showDialog = true;
        } else {
            // Adding template: display confirmation dialog if NOT isInceptiaUser
            if (!isInceptiaUser) showDialog = true;
        }
        // If we're not showing the dialog, we're done
        if (!showDialog) return;

        // Only want to show the dialog if ALL formik fields are valid
        let isValid = false;
        if (formik.values.communication_type === NotificationCommunicationTypeEnum.Email) {
            // Calling setFieldValue('body', content) above does not "instantly" update the formik value, so validation against ALL fields will fail
            // So, have yup validate all the other fields besides the body
            // (We essentially validated the body above by getting the content from the editor)
            const updatedValidationSchema = validationSchema.clone().shape({
                body: yup.string().notRequired(),
            });
            isValid = await updatedValidationSchema.isValid(formik.values);
        } else {
            isValid = await validationSchema.isValid(formik.values);
        }

        if (isValid) {
            // Need to hang onto the formik object for the dialog
            // If user accepts the pending approval, we need to call the formik submit function from the ConfirmationDialog
            setFormik(formik);

            // Stop the form from submitting and show the dialog
            e.preventDefault();
            setPendingApprovalDialogOptions({
                isOpen: true,
                name: formik.values.name,
                adding: formik.values.id ? false : true,
                communicationType: formik.values.communication_type
            });
        }
    };

    let sendSample = useCallback(async (ope_id: string | undefined, template: NotificationTemplateDto) => {
        let notificationData: NotificationData[] = [{
            communication_type: template.communication_type,
            email_address: user?.email ?? undefined,
            phone_number: user?.phone ?? undefined,
            subject: template.subject,
            body: template.communication_type === NotificationCommunicationTypeEnum.Email ?
                // @ts-ignore - Typescript doesn't believe that the editor has a getContent() method
                editorRef.current?.getContent() : template.body,
            template_variables: {},
            ope_id: ope_id,
            email_sender_id: template.email_sender_id
        }];

        try {
            let response = await notificationService.SendNotification(notificationData);
            if (response?.successful_notifications?.length > 0) {
                enqueueSnackbar('Notification sent successfully!', { variant: "success" });
            } else {
                throw new Error('Notification not sent');
            }
        } catch (error) {
            enqueueSnackbar('Error sending notification!', { variant: "error" });
        }
    }, [user?.email, user?.phone]);

    const getCustomEditButtons = useCallback((ope_id: string | undefined, template: NotificationTemplateDto) => {
        let sampleButton = {
            text: "Send me a sample",
            action: async (t: NotificationTemplateDto) => await sendSample(ope_id, t)
        }
        return template.communication_type === NotificationCommunicationTypeEnum.Email ? [sampleButton] : [];
    }, [sendSample])

    return (
        <>
            <ManageCrudPage<NotificationTemplateDto>
                title="Manage Notification Templates"
                entityName="Template"
                initialValues={initialValues}
                validationSchema={validationSchema}
                fetchItems={fetchTemplates}
                isDefault={template => (isInceptiaOrganization && !isInceptiaUser) || template.org_id !== orgId}
                customSaveText={editItem => editItem ? (isInceptiaUser &&
                    editItem.status === NotificationTemplateStatusEnum.PendingApproval ?
                    "Approve Template" : "Update Template") : "Create Template"}
                createItem={(template: NotificationTemplateDto) =>
                    notificationService.CreateTemplate({ ...template, org_id: orgId }).then(() => { })
                }
                updateItem={(id: number, template: NotificationTemplateDto) =>
                    notificationService.UpdateTemplate(id, template).then(() => { })
                }
                deleteItem={async template => await notificationService.DeleteTemplate(
                    template?.id, template?.org_id, template?.status)}
                renderFormFields={renderFormFields}
                renderTableColumns={renderTableColumns}
                renderTableHeaders={renderTableHeaders}
                customRowButtons={isInceptiaUser && isInceptiaOrganization ? [{
                    text: "Link",
                    action: template => {
                        setTemplateToLink(template);
                        setIsLinkDialogOpen(true);
                    }
                }] : []}
                opeid={opeid}
                getCustomEditButtons={getCustomEditButtons}
                canAddNew={isInceptiaUser || !isInceptiaOrganization}
                handleSubmitButtonClick={handleSubmitButtonClick}
            />
            <TemplateLinkForm
                isOpenDialog={isLinkDialogOpen}
                setIsOpenDialog={setIsLinkDialogOpen}
                template={templateToLink}
            />
            {pendingApprovalDialogOptions.isOpen && (
                <ConfirmationDialog
                    ariaPrefix="pending-approval"
                    dialogTitle="Pending Approval Confirmation"
                    singleButton={false}
                    ctaButtonText="Confirm"
                    ctaButtonWorkingText="Confirming..."
                    cancelButtonText="Cancel"
                    open={pendingApprovalDialogOptions.isOpen}
                    setOpenDialog={() => setPendingApprovalDialogOptions(prev => ({ ...prev, isOpen: false }))}
                    setIsWorking={(accepted: boolean) => {
                        if (accepted) formik.submitForm();
                        setPendingApprovalDialogOptions(prev => ({ ...prev, isOpen: false }));
                    }}
                >
                    <Typography variant='body2' sx={{ mb: 2 }}>
                        You're about to {pendingApprovalDialogOptions.adding ? 'add' : 'update'} the template <b>{pendingApprovalDialogOptions.name}</b>.
                    </Typography>
                    <Alert severity='info' color='warning' sx={{ mb: 0 }}>
                        {pendingApprovalDialogOptions.adding && (<>
                            <Typography variant='body2'>
                                Your new {pendingApprovalDialogOptions.communicationType === NotificationCommunicationTypeEnum.Email ? 'email' : 'SMS'} template will be submitted for review and approval by Inceptia before it can be used.
                            </Typography>
                        </>)}
                        {!pendingApprovalDialogOptions.adding && (<>
                            <Typography variant='body2' sx={{ mb: 1 }}>Your changes to the {pendingApprovalDialogOptions.communicationType === NotificationCommunicationTypeEnum.Email ? 'email' : 'SMS'} template will be reviewed and approved by Inceptia before they can be used.</Typography>
                            Until the template is approved, the previous version will remain in use.
                        </>)}
                    </Alert>
                </ConfirmationDialog>
            )}

        </>
    );
};

export default NotificationTemplateCrud;