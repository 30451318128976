import { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { cardSX, inceptiaGreenAlphaColors, pageHeaderSX } from '../utilities/CSS';
import KeycloakService from '../services/KeycloakService';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';


const NoReportsAvailable = () => {
  return (
    // Max width on the alert is dependent upon the content of the alert - adjust as needed
    <Alert icon={<InfoOutlinedIcon />} severity='success' sx={{ maxWidth: '675px' }}>
      <AlertTitle sx={{ mb: 1.5 }}>No Reports Available</AlertTitle>
      <Typography variant='body1' sx={{ mb: 0.75 }}>No reports are currently available for your account.</Typography>
      <Typography variant='body1'>If you have any questions or concerns, please don't hesitate to reach out to us.</Typography>
    </Alert>
  );
};

export default function AdminReports() {
  const [hasReportsAccess, setHasReportsAccess] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsAdmin(KeycloakService.hasGroup(['Admin']));

    if (['Admin', 'Manager', 'InstitutionAdmin'].some(g => KeycloakService.hasGroup([g]))) {
      setHasReportsAccess(true);
    }
  }, []);

  interface Report {
    report_name: string;
    route: string;
    has_access: boolean;
  };

  let reports: Report[] = [
    {
      report_name: "Binder Report",
      route: "binder",
      has_access: hasReportsAccess
    },
    {
      report_name: "Notification Report",
      route: "notification",
      has_access: hasReportsAccess
    },
    {
      report_name: "Pending Final Review Report",
      route: "pendingFinalReview",
      has_access: isAdmin
    }
  ];

  return (<>
    <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4.5 }}>Available Reports</Typography>

    <Paper sx={cardSX.paperMinHeight}>
      <Container sx={{ py: 4.5 }}>
        { ! hasReportsAccess && (<NoReportsAvailable />)}

        {/* {hasReportsAccess && (<ReportBinders />)} */}
        {hasReportsAccess && (
          <TableContainer>
            <Table aria-label='Binders report table' size='small'>
              <Box component={'caption'} sx={visuallyHidden}>Binders report table</Box>
              <TableHead>
                <TableRow>
                  <TableCell component="th" sx={{ fontWeight: 'bold' }}>REPORT NAME</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.map((report, index) => (
                  report.has_access && <TableRow
                    hover
                    key={`report-${index}`}
                    role='button' // Keyboard accessibility
                    tabIndex={0} // Keyboard accessibility
                    sx={{
                      cursor: 'pointer',
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:focus-visible': { backgroundColor: inceptiaGreenAlphaColors.hover }
                    }}
                    onClick={() => navigate(report.route)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) => {
                      // Keyboard accessibility - open form when pressing enter or space
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        navigate(report.route);
                      }
                    }}
                  >
                    <TableCell sx={{ py: 1.125, color: 'InceptiaGreen.main', fontWeight: "500" }}>{report.report_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </Paper>
  </>);
}
