import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, Hidden, Link, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { type TextFieldProps } from '@mui/material';
import { formatPhoneNumber, ProfileCompleteProps } from '../utilities/HelperFunctions';
import { cardSX, inceptiaGreenAlphaColors } from '../utilities/CSS';
import userService from '../services/UserService';
import KeycloakService from '../services/KeycloakService';
import { UserContext } from '../contexts/UserContext';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormik } from "formik";
import { OrgPurlProps, SSNRequiredProps } from '../components/SearchParamHandler';
import { iStudentSchoolDTO } from '../utilities/APIInterfaces';
import useFormikFirstErrorFocus from '../utilities/useFormikFirstErrorFocus';

const minimumAgeInYears = 13;

// Types for each "step" in the sign up process
type Step1Type = {
  firstName: string;
  lastName: string;
  phone: string;
  studentId?: string;
  ssn: string;
  dob: string;
  studentLastName?: string;
};
type Step2Type = {
  // !!! E-Sign removed for now...
  // eSign: boolean;
  smsConsent: 'true' | 'false' | ''; // Need an "undecided" option - so boolean no longer worked
};

type SignUpStep2Props = {
  setCurrentStep: React.Dispatch<React.SetStateAction<1 | 2>>;
  step2Data: Step2Type;
  setStep2Data: React.Dispatch<React.SetStateAction<Step2Type>>;
  isSaving: boolean;
  createUserAsync: () => void;
};
const SignUpStep2 = (props: SignUpStep2Props) => {
  const [smsConsentError, setSmsConsentError] = useState(false);
  const step2Formik = useFormik({
    initialValues: props.step2Data,
    validationSchema: yup.object({
      smsConsent: yup.string().trim().required('Please choose a text messaging option')
    }),
    onSubmit: (values) => {
      props.setStep2Data(values);
      props.createUserAsync();
    }
  });

  useEffect(() => {
    if ( ! step2Formik.isValid && step2Formik.submitCount !== 0 && step2Formik.isSubmitting) {
      setSmsConsentError(true);
    }
  }, [step2Formik.submitCount, step2Formik.isValid, step2Formik.isSubmitting]);

  return (<>
    <Box component='form' id='signUpStep2' noValidate onSubmit={step2Formik.handleSubmit}>
      {/* !!! E-Sign (removed for now) ------------------------------------------ */}

      {/* Text Messaging Opt In ------------------------------------------------- */}
      <FormControl sx={{ mb: 5.5 }}>
        <Typography variant='body1' id='smsConsentLabel' sx={{ fontWeight: 500, mb: 0.5 }}>Text Messaging Opt In</Typography>
        <RadioGroup
          aria-labelledby='smsConsentLabel'
          defaultValue={props.step2Data.smsConsent}
          name='smsConsent'
          value={props.step2Data.smsConsent}
          onChange={step2Formik.handleChange}
          onBlur={step2Formik.handleBlur}
        >
          <FormControlLabel
            value={true}
            onChange={() => props.setStep2Data({ ...props.step2Data, smsConsent: 'true' })}
            control={<Radio value='true' />}
            label={<Typography variant='body2'>I authorize Inceptia to contact me via text message. Carrier message and data rates may apply. <b>(Recommended)</b></Typography>}
            sx={{ mr: 0 }}
          />
          <FormControlLabel
            value={false}
            onChange={() => props.setStep2Data({ ...props.step2Data, smsConsent: 'false' })}
            control={<Radio value='false' />}
            label={<Typography variant='body2'>Decline to authorize text messaging, contact me via email</Typography>}
            sx={{ mr: 0 }}
          />
        </RadioGroup>
        {smsConsentError && <FormHelperText error>{step2Formik.errors.smsConsent}</FormHelperText>}
      </FormControl>
    </Box>

    <Typography variant='body2' sx={{ textAlign: 'center', mb: 2.25 }}>
      By continuing, you agree to Inceptia's
      <Link
        href='https://www.inceptia.org/privacy/#sms'
        target='_blank'
        sx={{
          color: 'var(--link-color)',
          fontWeight: 'bold',
          ml: 0.5,
          textDecoration: 'none',
          '&:hover, &:focus': {
            textDecoration: 'underline'
          }
        }}
      >SMS Terms of Service</Link>
    </Typography>

    <Box sx={{
      color: 'InceptiaGreen.main',
      display: 'flex',
      gap: 2
    }}>
      <Button
        variant='outlined'
        color='inherit'
        disabled={props.isSaving}
        sx={{
          fontSize: '1rem',
          textTransform: 'none',
          width: '50%',
          '&:hover, &:focus': {
            backgroundColor: inceptiaGreenAlphaColors.hover
          }
        }}
        onClick={() => props.setCurrentStep(1)}
      >Back</Button>

      <Button
        variant='contained' 
        disabled={props.isSaving}
        type='submit'
        form='signUpStep2'
        sx={{
          bgcolor: 'InceptiaGreen.main',
          fontSize: '1rem',
          textTransform: 'none',
          width: '50%',
          '&:hover': {
            backgroundColor: 'InceptiaGreen.dark',
          }
        }}
      >
      {props.isSaving ? (<>
          <CircularProgress
            color='inherit'
            size={20}
            sx={{ mr: 1 }}
          />
          Creating...
      </>) : 'Create Account'}</Button>

    </Box>
  </>);
};

type SignUpTextFieldProps = {
  formControlMB?: number;
  id: string;
  labelText: string;
  placeholder: string;
  readOnly?: boolean;
  maxLength?: number;
} & TextFieldProps;
const SignUpTextField = ({ formControlMB, id, labelText, placeholder, readOnly, maxLength, ...props }: SignUpTextFieldProps) => {
  return (
    <FormControl fullWidth sx={{...(formControlMB ? { mb: formControlMB } : { mb: 3.75 })}}>
      <Typography component='label' htmlFor={id} sx={{ fontWeight: 500, mb: '15px' }}>{labelText}</Typography>
      <TextField
        variant='outlined'
        inputProps={{
          id: id,

          // Instead of turning off autocomplete at the form-level, only really need to turn it off for the ssn input
          ...(id === 'ssn' && { autoComplete: 'off' }),

          // Don't allow future dates for dob
          ...(id === 'dob' && { max: new Date().toISOString().split('T')[0] }),

          maxLength: maxLength,
          placeholder: placeholder,
          readOnly: readOnly,
          sx: {
            py: '11px',
            ...(props.type === 'date' && { textTransform: 'uppercase' }) // Want the 'mm/dd/yyyy' placeholder to be uppercase to match Figma
          }
        }}
        onFocus={e => e.target.select()}
        sx={{
          '&:has([readonly]) ': {
            '& .MuiInputBase-root': {
              backgroundColor: '#f6f6f6',
            }
          },
          // Input's border that has an error should be red even when it has focus (without this the focused border color is the Inceptia Green)
          '& .Mui-error.Mui-focused fieldset': {
            borderColor: '#d32f2f'
          }
        }}
        {...props}
      />
    </FormControl>
  );
};

interface SignUpProps {
  orgPurlProps : OrgPurlProps;
  ssnRequiredProps : SSNRequiredProps;
  profileCompleteProps : ProfileCompleteProps;
}
export default function SignUp(props : SignUpProps) {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [step1Data, setStep1Data] = useState<Step1Type>({} as Step1Type);

  // !!! E-Sign removed for now...
  // const [step2Data, setStep2Data] = useState<Step2Type>({ eSign: true, smsConsent: true } as Step2Type);

  // Default "Text Messaging Opt In" to neither 'true' or 'false'
  const [step2Data, setStep2Data] = useState<Step2Type>({ smsConsent: '' } as Step2Type);

  const [isSaving, setIsSaving] = useState(false);

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    studentId: '',
    requireSSN: props.ssnRequiredProps.ssnRequired,
    ssn: '',
    dob: ''
  };
  const validationSchema = yup.object({
    firstName: yup.string().trim().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    phone: yup.string().required('Mobile number is required')
      .min(10, 'Mobile number must be 10 digits'),
    studentId: yup.string()
      .min(2, 'Student ID must be at least 2 characters')
      .max(10, "Student ID can't be longer than 10 characters"),

    requireSSN: yup.string(),

    // https://stackoverflow.com/a/34523303
    // https://stackoverflow.com/questions/77530420/cannot-make-when-condition-working-with-yup-validation-schema
    ssn: yup.string()
      .when('requireSSN', {
        is: 'REQUIRED',
        then: (schema) => schema
          .required('SSN is required')
          .matches(/^(?!000|666)[0-9]{3}([ -]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/, 'Please enter a valid SSN'),
        })
      .when('requireSSN', {
        is: 'OPTIONAL',
        then: (schema) => schema
          .matches(/^(?!000|666)[0-9]{3}([ -]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$|^$/, 'Please enter a valid SSN'),
        }),
  
    dob: yup.date().nullable()
      .test('dob', `Must be ${minimumAgeInYears} years or older`, function (value, ctx) {
        if (value === null || value === undefined) {
          return ctx.createError({
            path: 'dob',
            message: 'Please enter or select a valid date',
            type: 'invalidDate',
          });
        }
        const dob = new Date(value);
        dob.setHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const dateDiffInMs = currentDate.getTime() - dob.getTime();
        const dateDiffInYears = Math.floor(dateDiffInMs / (1000 * 60 * 60 * 24 * 365.25)); // account for leap years
        return dateDiffInYears >= minimumAgeInYears ? true : ctx.createError();
      }).required('Please enter or select a valid date')
  });
  const signUpFormik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setStep1Data(values);
      if (showConfirmAlert) setCurrentStep(2);
      setShowConfirmAlert(prev => !prev);
    }
  });

  useEffect(() => {
    signUpFormik.values.requireSSN = props.ssnRequiredProps.ssnRequired;
  }, [props.ssnRequiredProps.ssnRequired]);

  useFormikFirstErrorFocus({ formik: signUpFormik });

  const createUserAsync = async () => {
    const token = KeycloakService.getTokenParsed();

    setIsSaving(true);

    // Trim some fields and strip phone and ssn down to just digits
    const newStep1Data = {
      ...step1Data,
      firstName: step1Data.firstName.trim(),
      lastName: step1Data.lastName.trim(),
      studentId: step1Data.studentId?.trim(),
      phone: step1Data.phone.replace(/\D/g, ''),
      ssn: step1Data.ssn.replace(/\D/g, '')
    };
    const userObj = userService.MapToDto({
      ...newStep1Data,
      smsConsent: step2Data.smsConsent === 'true',
      authId: token?.sub,
      email: token?.email,
      ssnDobSet: true
    });
    if (props.orgPurlProps?.orgPurl) {
      let schoolObj: iStudentSchoolDTO = {
        school_Student_Id: step1Data.studentId || null,
        purl: props.orgPurlProps?.orgPurl || null,
        school_Id: null,
        id: null,
        user_Id: null,
        school_Name: null
      }
      userObj.studentSchools = [schoolObj];
    };

    userService.CreateUser(userObj, (v) => v)
    .then(result => {
      if(result){
        userContext.setUser(userService.MapToUser(result));
        props.profileCompleteProps.setProfileComplete(true);
        navigate('/dashboard');
      }
    }).catch(error => {
      enqueueSnackbar(error.toString());
      console.log("createUserAsync 1", error);
    }).finally(() => {
      setIsSaving(false);
    });
  };

  return (
    <Container
      disableGutters
      maxWidth='sm' // 600px
      sx={{
        pt: { xs: 3.5, sm: '57px' },
        mb: { xs: 3, sm: 4.5 }
      }}
    >
      <Box
        component='img'
        src={process.env.PUBLIC_URL + '/logo_inceptia_login.png'}
        alt='Inceptia logo'
        sx={{
          display: 'block',
          mx: 'auto',
          mb: { xs: 3, sm: '46px' },
          pr: '15px' // Helps with horizontal centering
        }}
      />

      <Paper sx={{
        ...cardSX.paper,
        borderTop: '3px solid var(--inceptia-green)',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        p: { xs: '18px 15px', sm: '36px 30px 35px' }
      }}>

        <Typography
          variant='h1'
          sx={{
            fontSize: '1.5rem',
            fontWeight: 500,
            ...(currentStep === 1 ? { mb: 3.75 } : { mb: 5 }),
            textAlign: 'center'
          }}
        >{currentStep === 1 ? 'Welcome to Inceptia' : 'Finish setting up your account'}</Typography>

        {/* Step 1 of 2 ======================================================= */}
        {currentStep === 1 && (<>
          <Typography
            variant='body2'
            sx={{
              mb: 3.75,
              textAlign: 'center',
              textWrap: 'balance'
            }}
          >Please complete the form below to verify we have your information on file.</Typography>

          <Box component='form' id='signUpForm' noValidate onSubmit={signUpFormik.handleSubmit}>

            {/* First Name ------------------------------------------------------ */}
            <SignUpTextField
              id='firstName'
              labelText='First Name'
              autoFocus
              placeholder='Enter your first name'
              value={signUpFormik.values.firstName}
              onChange={signUpFormik.handleChange}
              onBlur={signUpFormik.handleBlur}
              error={signUpFormik.touched.firstName && Boolean(signUpFormik.errors.firstName)}
              helperText={signUpFormik.touched.firstName && signUpFormik.errors.firstName}
            />

            {/* Last Name ------------------------------------------------------- */}
            <SignUpTextField
              id='lastName'
              labelText='Last Name'
              placeholder='Enter your last name'
              value={signUpFormik.values.lastName}
              onChange={signUpFormik.handleChange}
              onBlur={signUpFormik.handleBlur}
              error={signUpFormik.touched.lastName && Boolean(signUpFormik.errors.lastName)}
              helperText={signUpFormik.touched.lastName && signUpFormik.errors.lastName}
            />

            {/* Mobile Number --------------------------------------------------- */}
            <SignUpTextField
              id='phone'
              labelText='Mobile Number'
              maxLength={14} // The 10 digits formatted will = 14 - (###) ###-####
              placeholder='Enter your mobile number'
              value={formatPhoneNumber(signUpFormik.values.phone)}
              onChange={signUpFormik.handleChange}
              onBlur={signUpFormik.handleBlur}
              error={signUpFormik.touched.phone && Boolean(signUpFormik.errors.phone)}
              helperText={signUpFormik.touched.phone && signUpFormik.errors.phone}
            />

            {/* University Student ID (optional) -------------------------------- */}
            <SignUpTextField
              id='studentId'
              labelText='University Student ID'

              // !!! Size of studentdashboard.student_school.SCHOOL_STUDENT_ID field - varchar(10)
              // Why let them type more if it will just get truncated during the save?
              maxLength={10}

              placeholder='Enter your student ID number'
              value={signUpFormik.values.studentId}
              onChange={signUpFormik.handleChange}
              onBlur={signUpFormik.handleBlur}
              error={signUpFormik.touched.studentId && Boolean(signUpFormik.errors.studentId)}
              helperText={signUpFormik.touched.studentId && signUpFormik.errors.studentId}
            />

            {/* SSN ------------------------------------------------------------- */}
            {(props.ssnRequiredProps.ssnRequired != "HIDDEN") && (
              <>
                <SignUpTextField
                  formControlMB={0.5}
                  id='ssn'
                  labelText={(props.ssnRequiredProps.ssnRequired == "OPTIONAL") 
                      ? 'Social Security Number (Optional)' 
                      : 'Social Security Number' 
                    }
                  placeholder='###-##-####'
                  value={signUpFormik.values.ssn}
                  onChange={signUpFormik.handleChange}
                  onBlur={signUpFormik.handleBlur}
                  error={signUpFormik.touched.ssn && Boolean(signUpFormik.errors.ssn)}
                  helperText={signUpFormik.touched.ssn && signUpFormik.errors.ssn}
                />
                <Typography variant='body1' sx={{ mb: 3.75 }}>* This is used to link to school information, please verify accuracy</Typography>
              </>
            )}

            {/* DOB ------------------------------------------------------------- */}
            <SignUpTextField
              formControlMB={0.5}
              id='dob'
              labelText='Date of Birth'
              placeholder='MM/DD/YYYY'
              type='date'
              value={signUpFormik.values.dob}
              onChange={signUpFormik.handleChange}
              onBlur={signUpFormik.handleBlur}
              error={signUpFormik.touched.dob && Boolean(signUpFormik.errors.dob)}
              helperText={signUpFormik.touched.dob && signUpFormik.errors.dob}
            />
            <Typography variant='body1' sx={{ mb: showConfirmAlert ? 3.25 : 5.75 }}>* This is used to link to school information, please verify accuracy</Typography>

          </Box>

          {showConfirmAlert && (
            <Alert
              severity='warning'
              sx={{
                backgroundColor: '#f1f1f1',
                color: 'var(--text-color)',
                fontSize: '1rem',
                fontWeight: 'bold',
                mb: 4,
                py: 3,
                px: 1.5,
                '& .MuiAlert-icon': {
                  alignItems: 'center',
                  fontSize: '2rem',
                  mr: 1
                }
              }}
            >
              Please confirm the above information is correct as inaccuracies can cause significant delays in submission processing.
            </Alert>
          )}
    
          <Button
            variant='contained'
            type='submit'
            form='signUpForm'
            sx={{
              bgcolor: 'InceptiaGreen.main',
              fontSize: '1rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'InceptiaGreen.dark',
              },
            }}
          >{showConfirmAlert ? 'Confirm' : 'Next'}</Button>

        </>)}{/* Step 1 of 2 */}

        {/* Step 2 of 2 ======================================================= */}
        {currentStep === 2 && (
          <SignUpStep2
            setCurrentStep={setCurrentStep}
            step2Data={step2Data}
            setStep2Data={setStep2Data}
            isSaving={isSaving}
            createUserAsync={createUserAsync}
          />
        )}
      </Paper>
    </Container>
  );
}
