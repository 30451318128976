import { useCallback, useState } from 'react';
import { Grid, TextField, Typography, TableCell, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as yup from 'yup';
import notificationService from '../../services/NotificationService';
import { NotificationCommunicationTypeEnum, NotificationScheduleDto, NotificationScheduleDtoIntervalTypeEnum, NotificationTemplateDto, NotificationTemplateStatusEnum } from '../../apis/notifications';
import ManageCrudPage, { DropdownStyles } from './ManageCrudPage';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StatusLabel } from '../../utilities/FormEnums';

interface NotificationScheduleCrudProps {
    orgId: number | undefined;
    templates: NotificationTemplateDto[];
    isInceptiaUser: boolean;
}

const NotificationScheduleCrud = ({ orgId = 0, templates, isInceptiaUser }: NotificationScheduleCrudProps) => {
    const [schedules, setSchedules] = useState<NotificationScheduleDto[]>([]);
    
    const initialValues: {
        start_date: Date;
        end_date: Date;
        send_time: string;
        timezone: string;
        org_id: number;
        notification_template_id?: number;
        status_id?: number;
    } = {
        start_date: new Date(),
        end_date: new Date(),
        send_time: '00:00:00',
        timezone: 'CDT',
        org_id: orgId
    };

    const validationSchema = yup.object().shape({
        start_date: yup.date().required('Start date is required'),
        end_date: yup.date().required('End date is required'),
        send_time: yup.string().required('Send time is required'),
        timezone: yup.string().required('Timezone is required'),
        interval_type: yup.string().oneOf(Object.values(NotificationScheduleDtoIntervalTypeEnum)).notRequired(),
        interval_value: yup.number().min(1).notRequired(),
        specific_days_of_week: yup.array().of(yup.number()).notRequired(),
        specific_days_of_month: yup.array().of(yup.number()).notRequired(),
        max_notification_attempts: yup.number().nullable().notRequired(),
        notification_template_id: yup.number().nullable().notRequired()
    });

    let formatDate = (date: Date | string | undefined | null) => {
        return date ? new Date(date) : undefined;
    };

    let getSelectableTemplates = (values : NotificationScheduleDto): NotificationTemplateDto[] => {
        let temps = templates ?? [];
        let scheds = schedules ?? [];
        temps = temps.filter(t => (t.org_id === orgId && t.status === NotificationTemplateStatusEnum.Approved) 
            || t.id === values.notification_template_id);
        scheds = scheds.filter(s => s.notification_template_id !== values.notification_template_id);
        return [{
            id: undefined, 
            name: " - None - ",
            communication_type: NotificationCommunicationTypeEnum.Email,
            org_id: orgId
        }, ...temps.filter(t => !scheds.some(s => s.notification_template_id === t.id))];
    };

    const renderFormFields = (formik: any, isCreate: boolean) => {
        let readOnly: boolean = !isInceptiaUser || (!isCreate && formik.values.org_id !== orgId);
        let selectableTemplates: NotificationTemplateDto[] = getSelectableTemplates(formik.values);
        
        return (<>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container rowGap={4} columnSpacing={3.5} sx={{ mb: 6.25 }}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Start Date"
                            value={formatDate(formik.values.start_date)}
                            onChange={(value) => formik.setFieldValue('start_date', value || null)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: formik.touched.start_date && Boolean(formik.errors.start_date),
                                    helperText: formik.touched.start_date && formik.errors.start_date,
                                    InputProps: {
                                        className: readOnly ? "Mui-disabled" : undefined
                                    },
                                    inputProps: {
                                        readOnly: readOnly
                                    }
                                },
                            }}
                            disabled={readOnly ? true : undefined}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="End Date"
                            value={formatDate(formik.values.end_date)}
                            onChange={(value) => formik.setFieldValue('end_date', value || null)}
                            disabled={readOnly ? true : undefined}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: formik.touched.end_date && Boolean(formik.errors.end_date),
                                    helperText: formik.touched.end_date && formik.errors.end_date,
                                    InputProps: {
                                        className: readOnly ? "Mui-disabled" : undefined
                                    },
                                    inputProps: {
                                        readOnly: readOnly
                                    }
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth error={formik.touched.interval_type && Boolean(formik.errors.interval_type)} sx={DropdownStyles}>
                            <InputLabel id="interval-type-label">Interval Type</InputLabel>
                            <Select
                                labelId="interval-type-label"
                                id="interval_type"
                                name="interval_type"
                                label="Interval Type"
                                value={formik.values.interval_type}
                                onChange={formik.handleChange}
                                disabled={readOnly ? true : undefined}
                            >
                                {Object.values(NotificationScheduleDtoIntervalTypeEnum).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.interval_type && formik.errors.interval_type ? (
                                <Typography variant="caption" color="error">{String(formik.errors.interval_type)}</Typography>
                            ) : null}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="interval_value"
                            label="Interval Value"
                            type="number"
                            fullWidth
                            value={formik.values.interval_value}
                            onChange={formik.handleChange}
                            error={formik.touched.interval_value && Boolean(formik.errors.interval_value)}
                            helperText={formik.touched.interval_value && formik.errors.interval_value}
                            inputProps={{
                                readOnly: readOnly
                            }}
                            InputProps = {{
                                className: readOnly ? "Mui-disabled" : undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="specific_days_of_month"
                            label="Specific Days of Month"
                            fullWidth
                            value={formik.values.specific_days_of_month?.join(', ')}
                            onChange={(e) => formik.setFieldValue('specific_days_of_month', e.target.value.split(',').map(Number))}
                            helperText="Enter comma-separated values for specific days (e.g., 1, 15, 30)"
                            inputProps={{
                                readOnly: readOnly
                            }}
                            InputProps = {{
                                className: readOnly ? "Mui-disabled" : undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="specific_days_of_week"
                            label="Specific Days of Week"
                            fullWidth
                            value={formik.values.specific_days_of_week?.join(', ')}
                            onChange={(e) => formik.setFieldValue('specific_days_of_week', e.target.value.split(',').map(Number))}
                            helperText="Enter comma-separated values for specific days of the week (e.g., 0 for Sunday, 1 for Monday)"
                            inputProps={{
                                readOnly: readOnly
                            }}
                            InputProps = {{
                                className: readOnly ? "Mui-disabled" : undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="max_notification_attempts"
                            label="Max Notification Attempts"
                            type="number"
                            fullWidth
                            value={formik.values.max_notification_attempts || ''}
                            onChange={formik.handleChange}
                            error={formik.touched.max_notification_attempts && Boolean(formik.errors.max_notification_attempts)}
                            helperText={formik.touched.max_notification_attempts && formik.errors.max_notification_attempts}
                            inputProps={{
                                readOnly: readOnly
                            }}
                            InputProps = {{
                                className: readOnly ? "Mui-disabled" : undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth error={formik.touched.notification_template_id && Boolean(formik.errors.notification_template_id)} sx={DropdownStyles}>
                            <InputLabel id="template-label">Template</InputLabel>
                            <Select
                                labelId="template-label"
                                id="notification_template_id"
                                name="notification_template_id"
                                label="Template"
                                value={formik.values.notification_template_id}
                                onChange={formik.handleChange}
                                disabled={readOnly ? true : undefined}
                            >
                                {selectableTemplates.map((template) => (
                                    <MenuItem key={template?.id} value={template?.id}>
                                        {template?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.notification_template_id && formik.errors.notification_template_id ? (
                                <Typography variant="caption" color="error">{String(formik.errors.notification_template_id)}</Typography>
                            ) : null}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth error={formik.touched.status_id && Boolean(formik.errors.status_id)} sx={DropdownStyles}>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status_id"
                                name="status_id"
                                label="Status"
                                value={formik.values.status_id}
                                onChange={formik.handleChange}
                                disabled={readOnly ? true : undefined}
                            >
                                {[...Array.from(StatusLabel).filter(s => s?.[0]), [undefined, "None"]].map((status) => (
                                    <MenuItem key={status?.[0] ?? undefined} value={status?.[0] ?? undefined}>
                                        {status?.[1]}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.status_id && formik.errors.status_id ? (
                                <Typography variant="caption" color="error">{String(formik.errors.status_id)}</Typography>
                            ) : null}
                        </FormControl>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </>);
    }

    const renderTableHeaders = () => (
        <>
            <TableCell>Start Date (* = Default)</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Template Name</TableCell>
            <TableCell>Form Status</TableCell>
            <TableCell>Interval Type</TableCell>
            <TableCell>Interval Value</TableCell>
        </>
    );

    const renderTableColumns = (item: NotificationScheduleDto) => {
      const templateName = templates?.find(t => t.id === item.notification_template_id)?.name || '';

      return (<>
        <TableCell>{item.org_id !== orgId && "*"}{new Date(item.start_date).toLocaleDateString()}</TableCell>
        <TableCell>{new Date(item.end_date).toLocaleDateString()}</TableCell>
        <TableCell>{templateName}</TableCell>
        <TableCell>{item.status_id ? StatusLabel.get(item.status_id) : ''}</TableCell>
        <TableCell>{item.interval_type}</TableCell>
        <TableCell>{item.interval_value}</TableCell>
      </>);
    };

    const fetchItems = useCallback(async () => {
        let schedules = await notificationService.GetSchedulesByOrgId(orgId);
        if(schedules)
            schedules = schedules.sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
        setSchedules(schedules);
        return schedules;
    }, [orgId]);

    return (
        <ManageCrudPage<NotificationScheduleDto>
            title="Manage Notification Schedules"
            entityName="Schedule"
            initialValues={initialValues}
            validationSchema={validationSchema}
            fetchItems={fetchItems}
            isDefault={s => s.org_id !== orgId || !isInceptiaUser}
            createItem={(schedule: NotificationScheduleDto) =>
                notificationService.CreateSchedule(schedule).then(() => {})
            }
            updateItem={(id: number, schedule: NotificationScheduleDto) =>
                notificationService.UpdateSchedule(id, schedule).then(() => {})
            }
            deleteItem={async schedule => notificationService.DeleteSchedule(schedule?.id)}
            renderFormFields={renderFormFields}
            renderTableColumns={renderTableColumns}
            renderTableHeaders={renderTableHeaders}
            canAddNew={isInceptiaUser}
        />
    );
};

export default NotificationScheduleCrud;
