import { useContext, useEffect, useState } from 'react';
import { Link, TableCell, TableRow, Typography } from '@mui/material'
import { OrganizationContext } from '../contexts/OrganizationContext';
import reportService from '../services/ReportService';
import documentService from '../services/DocumentService';
import { iBinder } from '../utilities/APIInterfaces';
import { BinderStatusLabel } from '../utilities/FormEnums';
import { formatDate } from '../utilities/HelperFunctions';
import { enqueueSnackbar } from 'notistack';
import ReportTable from '../components/reports/ReportTable';

const numberOfDaysBindersAreKept = 10;

const tdSX = {
  py: 1.125
};

const ReportBinders = () => {
  const { organization } = useContext(OrganizationContext);
  const [isBinderDataLoaded, setIsBinderDataLoaded] = useState(false);
  const [binderData, setBinderData] = useState<iBinder[]>([]);

  useEffect(() => {
    // Want to get a fresh set of binders whenever the organization changes
    setIsBinderDataLoaded(false);
  }, [organization]);

  useEffect(() => {
    const GetBindersAsync = async () => {
      if (organization?.opeid) {
        await reportService.GetRequestedBinders(organization.opeid, (v) => v).then(result => {
          setBinderData(result);
        }).catch(error => {
          console.log('ExportBindersAsync 1', error);
        }).finally(() => {
          setIsBinderDataLoaded(true);
        });
      }
    };
  
    if (!isBinderDataLoaded && organization?.opeid) GetBindersAsync();
  }, [isBinderDataLoaded, organization?.opeid]);

  const handleDownloadFile = async (docUuid: string, filename: string) => {
    const blob = await documentService.GetFile(docUuid, (v) => v);
    if (blob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      enqueueSnackbar('Failed to retrieve file', { variant: "error" });
    }
  };

  return (<>
    <ReportTable 
      report_title='Binders'
      accessibility_title='Binders report table'
      empty_message='No binders found...'
      foot_notes={<Typography
        variant='body1'
        sx={{ fontSize: '0.75rem', color: 'grey.600', mt: 2, fontWeight: 500 }}
      >* Binders are retained for {numberOfDaysBindersAreKept} days before being deleted.</Typography>}
      is_loaded={isBinderDataLoaded}
      table_data={binderData}
      tableHeaders={["Date Requested", "Requested By", "Status", "Deletion Date*", "Download"]}
      render_row={row => {
        let expireDate = '';
        if (row.binderStatusId === 2) {
          const dt = new Date(row.statusDate);
          expireDate = new Date(dt.setDate(dt.getDate() + numberOfDaysBindersAreKept)).toString();
        }
        return (
          <TableRow key={row.id}>
            <TableCell sx={tdSX}>{row.createdOn}</TableCell>
            <TableCell sx={tdSX}>{row.requestedBy}</TableCell>
            <TableCell sx={tdSX}>{BinderStatusLabel.get(row.binderStatusId)}</TableCell>
            <TableCell sx={tdSX}>{formatDate(expireDate)}</TableCell>
            <TableCell sx={{...tdSX, color: 'InceptiaGreen.main', fontWeight: '500'}}>
              {row.fileName && (
                <Link
                  color='inherit'
                  sx={{ cursor: 'pointer', wordBreak: 'break-all' }}
                  onClick={() => handleDownloadFile(row.documentUuid, row.fileName || '')} 
                >{row.fileName}</Link>
              )}
            </TableCell>
          </TableRow>
        );
      }}
    />
  </>);
};

export default ReportBinders;
