import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import adminFormsService from '../services/AdminFormsService';
import { Status } from '../utilities/FormEnums';
import { iDocumentDTO, iUserDTO } from '../utilities/APIInterfaces';
import { stringToIntArray } from '../utilities/HelperFunctions';

export interface iAdminFormSubmissionsData {
  id: number;
  uuid: string;
  formsVersionId: number;
  submissionTypeId: number;
  submittedBy: number;
  authId: string;
  firstName: string | null;
  lastName: string | null;
  name: string | null;
  ssn: string | null;
  last4: string | null;
  schoolStudentId: string | null;
  dob: string | null;
  email: string | null;
  phone: string | null;
  attachments: number | null;
  lastUpdated: string | null;
  assigneeUuid: string | null;
  assignee: iUserDTO | null;
  tags: any[] | [];
  statusId: number | null;
  submittedDate: string | null;
  formJson: string | null;
  hasContributor: boolean;
  submissionJson: string | null;
  formUuid: string | null;
  documents: iDocumentDTO[] | [];
  selected: boolean;
};

type AdminFormSubmissionsDataContextType = {
  adminFormSubmissionsData: iAdminFormSubmissionsData[] | null | undefined;
  setAdminFormSubmissionsData: (value: iAdminFormSubmissionsData[] | null) => void;
  adminFormSubmissionsStatusTotals: {};
  setAdminFormSubmissionsStatusTotals: (value: {}) => void;
  isAdminFormSubmissionsDataLoaded: boolean;
  setIsAdminFormSubmissionsDataLoaded: (value: boolean) => void;
  adminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType;
  setAdminFormSubmissionsDataOptions: (value: AdminFormSubmissionsDataOptionsType) => void;
  setForceRefresh: (value: boolean) => void;
  adminFormSubmissionsDataTotalCount: number;
  setLoadFromLocalStorage: (value: boolean) => void;
};

export const AdminFormSubmissionsDataContext = createContext<AdminFormSubmissionsDataContextType>({
  adminFormSubmissionsData: null,
  setAdminFormSubmissionsData: value => console.warn('no admin form submissions provider'),
  adminFormSubmissionsStatusTotals: {},
  setAdminFormSubmissionsStatusTotals: value => console.warn('no admin form submissions provider'),
  isAdminFormSubmissionsDataLoaded: false,
  setIsAdminFormSubmissionsDataLoaded: value => console.warn('no admin form submissions provider'),
  adminFormSubmissionsDataOptions: {
    formUuid: '',
    statuses: [],
    searchQuery: '',
    statusChanged: false,
    sortField: '',
    sortDirection: 0,
    assignedToUuids: [],
    tags: [],
    dateRange: [],
    page: 0,
    rowsPerPage: 50
  },
  setAdminFormSubmissionsDataOptions: value => console.warn('no admin form submissions provider'),
  setForceRefresh: value => console.warn('no admin form submissions provider'),
  adminFormSubmissionsDataTotalCount: 0,
  setLoadFromLocalStorage: value => console.warn('no admin form submissions provider')
});
export const useAdminFormSubmissionsData = () => useContext(AdminFormSubmissionsDataContext);

interface AdminFormSubmissionsContextProps {
  children?: React.ReactNode
};

export type AdminFormSubmissionsFilterType = 'All' | Status;

export type AdminFormSubmissionsDataOptionsType = {
  formUuid: string;
  statuses: AdminFormSubmissionsFilterType[];
  searchQuery: string;
  statusChanged: boolean;
  sortField: string;
  sortDirection: number; // 0 = ascending, 1 = descending
  assignedToUuids: string[];
  tags: string[];
  dateRange: string[];
  page: number;
  rowsPerPage: number;
};

export const SetDataOptionsToLocalStorage = (adminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType) => {
  localStorage.setItem("formUuid", adminFormSubmissionsDataOptions.formUuid);
  localStorage.setItem("statuses", adminFormSubmissionsDataOptions.statuses.join());
  localStorage.setItem("searchQuery", adminFormSubmissionsDataOptions.searchQuery);
  localStorage.setItem("sortField", adminFormSubmissionsDataOptions.sortField);
  localStorage.setItem("sortDirection", adminFormSubmissionsDataOptions.sortDirection.toString());
  localStorage.setItem("assignedToUuids", adminFormSubmissionsDataOptions.assignedToUuids.join());
  localStorage.setItem("tagIds", adminFormSubmissionsDataOptions.tags.join());
  localStorage.setItem("dateRange", adminFormSubmissionsDataOptions.dateRange.join());
  localStorage.setItem("page", adminFormSubmissionsDataOptions.page.toString());
  localStorage.setItem("rowsPerPage", adminFormSubmissionsDataOptions.rowsPerPage.toString());
};

export const RemoveDataOptionsFromLocalStorage = () => {
  localStorage.removeItem("formUuid");
  localStorage.removeItem("statuses");
  localStorage.removeItem("searchQuery");
  localStorage.removeItem("sortField");
  localStorage.removeItem("sortDirection");
  localStorage.removeItem("assignedToUuids");
  localStorage.removeItem("tagIds");
  localStorage.removeItem("dateRange");
  localStorage.removeItem("page");
  localStorage.removeItem("rowsPerPage");
};

export const AdminFormSubmissionsDataContextProvider: React.FC<AdminFormSubmissionsContextProps> = ({ children }) => {
  const [adminFormSubmissionsData, setAdminFormSubmissionsData] = useState<iAdminFormSubmissionsData[] | null>(null);
  const [adminFormSubmissionsStatusTotals, setAdminFormSubmissionsStatusTotals] = useState({});
  const [isAdminFormSubmissionsDataLoaded, setIsAdminFormSubmissionsDataLoaded] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [adminFormSubmissionsDataTotalCount, setAdminFormSubmissionsDataTotalCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { formUuid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loadFromLocalStorage, setloadFromLocalStorage] = useState(true);
  
  const [adminFormSubmissionsDataOptions, setAdminFormSubmissionsDataOptions] = useState<AdminFormSubmissionsDataOptionsType>({
    formUuid: formUuid ?? '',
    statuses: [],
    searchQuery: '',
    statusChanged: false,
    sortField: '',
    sortDirection: 0,
    assignedToUuids: [],
    tags: [],
    dateRange: [],
    page: 0,
    rowsPerPage: 50
  });
  async function GetFormSubmissionsData(options: AdminFormSubmissionsDataOptionsType) {
    setIsLoading(true);
    await adminFormsService.GetFormSubmissionsAndStatusTotals(options, (v) => v)
      .then(result => {
        const { totalCount, submissions, statusTotals } = result;
        submissions ? setAdminFormSubmissionsData(submissions as iAdminFormSubmissionsData[]) : setAdminFormSubmissionsData([]);
        statusTotals ? setAdminFormSubmissionsStatusTotals(statusTotals) : setAdminFormSubmissionsStatusTotals({});
        setAdminFormSubmissionsDataTotalCount(totalCount);
      })
      .catch((error) => {
        enqueueSnackbar(error.toString());
        console.log("GetFormSubmissionsData 1", error);
      }).finally(() => {
        setIsAdminFormSubmissionsDataLoaded(true);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    if (localStorage.getItem("formUuid") && formUuid !== localStorage.getItem("formUuid")!) {
      RemoveDataOptionsFromLocalStorage();
      setloadFromLocalStorage(false);
    }
    if (localStorage.getItem("formUuid") && loadFromLocalStorage) {
      let loadedAdminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType = MapLocalStorageToDataOptions();
      setAdminFormSubmissionsDataOptions(loadedAdminFormSubmissionsDataOptions);
      GetFormSubmissionsData(loadedAdminFormSubmissionsDataOptions);
      setloadFromLocalStorage(false);
    }
    else if (!isLoading && adminFormSubmissionsDataOptions?.formUuid) {
      GetFormSubmissionsData(adminFormSubmissionsDataOptions);
    }
  }, [enqueueSnackbar, adminFormSubmissionsDataOptions]);

  useEffect(() => {
    if (forceRefresh) {
      GetFormSubmissionsData(adminFormSubmissionsDataOptions);
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  const MapLocalStorageToDataOptions = (): AdminFormSubmissionsDataOptionsType => {
    let formUuid: string = localStorage.getItem("formUuid") ? localStorage.getItem("formUuid")! : "";
    let statuses: AdminFormSubmissionsFilterType[] = localStorage.getItem("statuses") ? stringToIntArray(localStorage.getItem("statuses")!, ",") : [];
    let searchQuery: string = localStorage.getItem("searchQuery") ? localStorage.getItem("searchQuery")! : "";
    let sortField: string = localStorage.getItem("sortField") ? localStorage.getItem("sortField")! : "";
    let sortDirection: number = localStorage.getItem("sortDirection") ? Number(localStorage.getItem("sortDirection")!) : 0;
    let assignedToUuids: string[] = localStorage.getItem("assignedToUuids") ? localStorage.getItem("assignedToUuids")!.split(",") : [];
    let tags: string[] = localStorage.getItem("tagIds") ? localStorage.getItem("tagIds")!.split(",") : [];
    let dateRange: string[] = localStorage.getItem("dateRange") ? localStorage.getItem("dateRange")!.split(",") : [];
    let page: number = localStorage.getItem("page") ? Number(localStorage.getItem("page")!) : 0;
    let rowsPerPage: number = localStorage.getItem("rowsPerPage") ? Number(localStorage.getItem("rowsPerPage")!) : 0;
  
    const updatedAdminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType = {
      ...adminFormSubmissionsDataOptions,
      formUuid: formUuid,
      statuses: statuses,
      searchQuery: searchQuery,
      sortField: sortField,
      sortDirection: sortDirection,
      assignedToUuids: assignedToUuids,
      tags: tags,
      dateRange: dateRange,
      page: page,
      rowsPerPage: rowsPerPage
    };
  
    return updatedAdminFormSubmissionsDataOptions;
  };

  return (
    <AdminFormSubmissionsDataContext.Provider
      value={{
        adminFormSubmissionsData,
        setAdminFormSubmissionsData,
        adminFormSubmissionsStatusTotals,
        setAdminFormSubmissionsStatusTotals,
        isAdminFormSubmissionsDataLoaded,
        setIsAdminFormSubmissionsDataLoaded,
        adminFormSubmissionsDataOptions,
        setAdminFormSubmissionsDataOptions,
        setForceRefresh,
        adminFormSubmissionsDataTotalCount,
        setLoadFromLocalStorage: setloadFromLocalStorage
      }}
    >
      {children}
    </AdminFormSubmissionsDataContext.Provider>
  );
}