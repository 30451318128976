import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TableCell, TablePagination, TableRow, TextField, Typography } from "@mui/material"
import { cardSX, inceptiaGreenAlphaColors } from "../utilities/CSS"
import { SentNotificationSearchDto } from "../apis/notifications/models/sent-notification-search-dto";
import { useCallback, useContext, useEffect, useState } from "react";
import { OrganizationContext } from "../contexts/OrganizationContext";
import { CommunicationTypeEnum, NotificationTemplateDto, NotificationTemplateStatusEnum, SentNotificationDto } from "../apis/notifications";
import { DropdownStyles } from "../components/notifications/ManageCrudPage";
import notificationService from "../services/NotificationService";
import ReportTable from "../components/reports/ReportTable";
import { tableRowsPerPageOptions } from '../utilities/Misc';

const NotificationsReport = () => {
    const { organization } = useContext(OrganizationContext);
    const [orgId, setOrgId] = useState<number | undefined>(undefined);
    const [templates, setTemplates] = useState<NotificationTemplateDto[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(true);
    const [sentNotifications, setSentNotifications] = useState<SentNotificationDto[]>([]);
    const [pagedNotifications, setPagedNotifications] = useState<SentNotificationDto[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
  
    const initialSearchModel : SentNotificationSearchDto = {
        recipient: '',
        communication_type: '',
        end_created_at: '',
        start_created_at: '',
        is_clicked: '',
        is_opened: '',
        notification_template_id: 0,
        org_id: orgId,
        successfully_sent: ''
    };

    const [searchModel, setSearchModel] = useState<SentNotificationSearchDto>(initialSearchModel);

    const tdSX = {
        py: 1.125
    };

    useEffect(() => {
        if (organization?.opeid === "INCEPTIA")
            setOrgId(undefined);
        else
            setOrgId(organization?.id);
        setSentNotifications([]);
    }, [organization]);

    useEffect(() => {
        let getTemplates = async () => {
            if (orgId) {
                let orgTemplates = await notificationService.GetTemplatesByOrgId(orgId);
                orgTemplates = orgTemplates.filter(ot => ot.status === NotificationTemplateStatusEnum.Approved);
                setTemplates(orgTemplates);
            } else {
                setTemplates(await notificationService.GetAllTemplates());
            }
        };
        getTemplates();
    }, [orgId]);

    const searchNotifications = useCallback(async () => {
        setIsLoaded(false);
        setPage(0);
        let sentNotifications = await notificationService.SearchSentNotifications({
            ...searchModel,
            recipient: searchModel.recipient && searchModel.recipient.length > 0 ?
                searchModel.recipient : undefined,
            notification_template_id: searchModel.notification_template_id && 
                searchModel.notification_template_id > 0 ? searchModel.notification_template_id : undefined,
            org_id: orgId
        });
        setSentNotifications(sentNotifications);
        setIsLoaded(true);
    }, [orgId, searchModel]);

    const handlePageChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };
    
    const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setPagedNotifications(sentNotifications.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage));
    }, [rowsPerPage, page, sentNotifications]);

    const renderForm = () => {
        const mainSX = {
            backgroundColor: "InceptiaGreen.main",
            "&:hover": {
                backgroundColor: "InceptiaGreen.dark"
            }
        };

        const secondarySX = {
            color: "InceptiaGreen.main",
            borderColor: "InceptiaGreen.main",
            "&:hover": {
                borderColor: "InceptiaGreen.dark",
                backgroundColor: inceptiaGreenAlphaColors.hover
            }
        };

        return (<>
            <Paper sx={{ ...cardSX.paper, mb: 4.5 }}>
                <Container sx={{ py: 4.5 }}>
                    <Grid container gap={2}>
                        <Grid container rowGap={4} columnSpacing={3.5} sx={{ mb: 1 }}>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth sx={DropdownStyles}>
                                    <InputLabel id="template-label">Template</InputLabel>
                                    <Select
                                        labelId="template-label"
                                        id="notification_template_id"
                                        name="notification_template_id"
                                        label="Template"
                                        value={searchModel.notification_template_id}
                                        onChange={e => setSearchModel(model => 
                                            ({ ...model, notification_template_id: e.target.value as number }))}
                                    >
                                        <MenuItem value={0}>&nbsp;</MenuItem>
                                        {templates.map((template) => (
                                            <MenuItem key={template?.id} value={template?.id}>
                                                {template?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    id="recipient"
                                    label="Recipient"
                                    fullWidth
                                    value={searchModel.recipient}
                                    onChange={e => setSearchModel(model => 
                                        ({ ...model, recipient: e.target.value }))}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="date"
                                    id="start_created_at"
                                    label="Start Date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                    value={searchModel.start_created_at}
                                    onChange={e => setSearchModel(model => 
                                        ({ ...model, start_created_at: e.target.value }))}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="date"
                                    id="end_created_at"
                                    label="End Date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                    value={searchModel.end_created_at}
                                    onChange={e => setSearchModel(model => 
                                        ({ ...model, end_created_at: e.target.value }))}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth sx={DropdownStyles}>
                                    <InputLabel id="communication-type-label">Communication Type</InputLabel>
                                    <Select
                                        labelId="communication-type-label"
                                        id="communication_type"
                                        name="communication_type"
                                        label="Communication Type"
                                        value={searchModel.communication_type}
                                        onChange={e => setSearchModel(model => 
                                            ({ ...model, communication_type: e.target.value }))}
                                    >
                                        <MenuItem value={""}>&nbsp;</MenuItem>
                                        {Object.values(CommunicationTypeEnum).map((type) => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth sx={DropdownStyles}>
                                    <InputLabel id="is-successful-label">Is Successful</InputLabel>
                                    <Select
                                        labelId="is-successful-label"
                                        id="successfully_sent"
                                        name="successfully_sent"
                                        label="Is Successful"
                                        value={searchModel.successfully_sent}
                                        onChange={e => setSearchModel(model => 
                                            ({ ...model, successfully_sent: e.target.value }))}
                                    >
                                        <MenuItem value={""}>&nbsp;</MenuItem>
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth sx={DropdownStyles}>
                                    <InputLabel id="is-opened-label">Is Opened</InputLabel>
                                    <Select
                                        labelId="is-opened-label"
                                        id="is_opened"
                                        name="is_opened"
                                        label="Is Opened"
                                        value={searchModel.is_opened}
                                        onChange={e => setSearchModel(model => 
                                            ({ ...model, is_opened: e.target.value }))}
                                    >
                                        <MenuItem value={""}>&nbsp;</MenuItem>
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth sx={DropdownStyles}>
                                    <InputLabel id="is-clicked-label">Is Clicked</InputLabel>
                                    <Select
                                        labelId="is-clicked-label"
                                        id="is_clicked"
                                        name="is_clicked"
                                        label="Is Clicked"
                                        value={searchModel.is_clicked}
                                        onChange={e => setSearchModel(model => 
                                            ({ ...model, is_clicked: e.target.value }))}
                                    >
                                        <MenuItem value={""}>&nbsp;</MenuItem>
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>ggggg
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button
                            sx={mainSX}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!isLoaded}
                            onClick={searchNotifications}
                        >
                            Search
                        </Button>
                        <Button variant="outlined" color="warning"
                            onClick={() => setSearchModel(initialSearchModel)}
                            sx={secondarySX}>
                            Clear
                        </Button>
                    </Grid>
                </Container>
            </Paper>
        </>);
    };

    return (<>
        <ReportTable
            accessibility_title="Notifications report table"
            report_title="Notifications"
            form={renderForm()}
            empty_message="No notifications found..."
            is_loaded={isLoaded}
            tableHeaders={["Template Name", "Type", "Recipient", "Successful", "Opened*", "Opened Date*", "Clicked*", "Created Date"]}
            table_data={pagedNotifications}
            foot_notes={<>
                <TablePagination
                    component='div'
                    count={sentNotifications?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    rowsPerPageOptions={tableRowsPerPageOptions}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    sx={{
                        '&, & .MuiTablePagination-input .MuiSvgIcon-root': { color: '#000' }
                    }}
                    slotProps={{
                        actions: {
                            previousButton: { 'aria-label': 'previous page' },
                            nextButton: { 'aria-label': 'next page' }
                        },
                        // MUI generates WAVE "Missing form label" error, the options below fix it
                        // https://stackoverflow.com/questions/66642553/tablepagination-mui-missing-form-label-accessibility-issue-in-wave-tool
                        select: {
                            id: 'rows-per-page-select',
                            inputProps: {
                                'aria-label': 'rows per page',
                                'aria-labelledby': 'rows-per-page-select'
                            }
                        }
                    }}
                />
                <Typography
                    variant='body1'
                    sx={{ fontSize: '0.75rem', color: 'grey.600', mt: 2, fontWeight: 500 }}
                >
                    * Opened and Clicked are only tracked for emails.
                </Typography>
            </>}
            render_row={(sentNotification: SentNotificationDto) => {
                let templateName: string | undefined = "- No Template -";
                if (sentNotification.notification_template_id) {
                    let relatedTemplates: NotificationTemplateDto[] | undefined = templates
                        .filter(t => t.id === sentNotification.notification_template_id);
                    let template = relatedTemplates && relatedTemplates.length > 0 ? relatedTemplates[0] : undefined;
                    templateName = template?.name;
                }

                return (
                    <TableRow key={sentNotification.id}>
                        <TableCell sx={tdSX}>{templateName ?? "- Deleted Template -"}</TableCell>
                        <TableCell sx={tdSX}>{sentNotification.communication_type as any === 0 ? 
                            "Email" : "SMS"}</TableCell>
                        <TableCell sx={tdSX}>{sentNotification.recipient}</TableCell>
                        <TableCell sx={tdSX}>{sentNotification.successfully_sent === true ? "Yes" :
                            sentNotification.successfully_sent === false ? "No" : ""}</TableCell>
                        <TableCell sx={tdSX}>{sentNotification.is_opened === true ? "Yes" :
                            sentNotification.is_opened === false ? "No" : ""}</TableCell>
                        <TableCell sx={tdSX}>{sentNotification.opened_date ? 
                            new Date(sentNotification.opened_date + "Z").toLocaleString() : ""}</TableCell>
                        <TableCell sx={tdSX}>{sentNotification.is_clicked === true ? "Yes" :
                            sentNotification.is_clicked === false ? "No" : ""}</TableCell>
                        <TableCell sx={tdSX}>{new Date(sentNotification.created_at + "Z").toLocaleString()}</TableCell>
                    </TableRow>
                );
            }}
        />
    </>);
};

export default NotificationsReport;
