import { useContext, useEffect, useState } from 'react';
import { TableCell, TablePagination, TableRow } from '@mui/material'
import { OrganizationContext } from '../contexts/OrganizationContext';
import reportService from '../services/ReportService';
import { iPendingFinalReviewRecord } from '../utilities/APIInterfaces';
import { formatDateTime } from '../utilities/HelperFunctions';
import ReportTable from '../components/reports/ReportTable';
import { tableRowsPerPageOptions } from '../utilities/Misc';

const tdSX = {
  py: 1.125
};

const PendingFinalReviewReport = () => {
  const { organization } = useContext(OrganizationContext);
  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [pendingFinalReviewReport, setPendingFinalReviewReport] = useState<iPendingFinalReviewRecord[]>([]);
  const [pagedReport, setPagedReport] = useState<iPendingFinalReviewRecord[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (organization?.opeid){
      GetReportAsync();
    }
  }, [organization?.opeid]);

  const GetReportAsync = async () => {
    setIsReportLoaded(false);
    await reportService.GetPendingFinalReviewReport(organization!.opeid, (v) => v).then(result => {
        setPendingFinalReviewReport(result);
        setIsReportLoaded(true);
    }).catch(error => {
        console.log(`Pending Final Review Report ${error}`);
    });
  };

  useEffect(() => {
    setPagedReport(pendingFinalReviewReport.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage));
  }, [rowsPerPage, page, pendingFinalReviewReport]);

  const handlePageChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(e.target.value, 10));
      setPage(0);
  };

  return (<>
    <ReportTable 
      report_title='Pending Final Review'
      accessibility_title='Pending Final Review Report'
      empty_message='No records found...'
      is_loaded={isReportLoaded}
      table_data={pagedReport}
      hasExport={true}
      csvFileName='PendingFinalReviewReport.csv'
      tableHeaders={["Form", "Submitted By", "Assigned To", "Sent To QC", "Status After QC", "QC Completed", "Current Status"]}
      foot_notes={<>
        <TablePagination
          component='div'
          count={pendingFinalReviewReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={tableRowsPerPageOptions}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          sx={{
              '&, & .MuiTablePagination-input .MuiSvgIcon-root': { color: '#000' }
          }}
          slotProps={{
            actions: {
              previousButton: { 'aria-label': 'previous page' },
              nextButton: { 'aria-label': 'next page' }
            },
            // MUI generates WAVE "Missing form label" error, the options below fix it
            // https://stackoverflow.com/questions/66642553/tablepagination-mui-missing-form-label-accessibility-issue-in-wave-tool
            select: {
              id: 'rows-per-page-select',
              inputProps: {
                  'aria-label': 'rows per page',
                  'aria-labelledby': 'rows-per-page-select'
              }
            }
          }}
        />
      </>}
      render_row={(row: iPendingFinalReviewRecord) => {
        return (
          <TableRow key={row.submissionFormId}>
            <TableCell sx={tdSX}>{row.form}</TableCell>
            <TableCell sx={tdSX}>{row.submittedBy}</TableCell>
            <TableCell sx={tdSX}>{row.assignedTo}</TableCell>
            <TableCell sx={tdSX}>{formatDateTime(row.sentToQc)}</TableCell>
            <TableCell sx={tdSX}>{row.statusAfterQc}</TableCell>
            <TableCell sx={tdSX}>{formatDateTime(row.qcCompleted)}</TableCell>
            <TableCell sx={tdSX}>{row.currentStatus}</TableCell>
          </TableRow>
        );
      }}
    />
  </>);
};

export default PendingFinalReviewReport;
