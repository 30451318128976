import { Box, Button, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { cardSX } from "../../utilities/CSS";
import { visuallyHidden } from '@mui/utils';
import TableRowsLoader, { TableLoaderProps } from "../TableRowsLoader";
import { createCsvContent, downloadCsv } from "../../utilities/HelperFunctions";


interface ReportTableProps {
    report_title: string,
    accessibility_title: string,
    tableHeaders: string[],
    empty_message: string,
    table_data: any[],
    render_row: (row: any) => any,
    is_loaded: boolean,
    form?: any,
    foot_notes?: any,
    hasExport?: boolean
    csvFileName?: string
}

const ReportTable = (props: ReportTableProps) => {    
    const TableLoader = ({ colsNum, rowsNum, animation = 'pulse' }: TableLoaderProps) => {
        return (
            <TableWrapper>
                <TableRowsLoader colsNum={colsNum} rowsNum={rowsNum} animation={animation} rowHeight={55} />
            </TableWrapper>
        );
    };
    
    const TableWrapper = ({ children }: { children: React.ReactNode }) => {
        const thSX = {
            color: '#1e1e1e',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap'
        };
    
        return (
            <TableContainer>
                <Table aria-label={props.accessibility_title} size='small'>
                    <Box component={'caption'} sx={visuallyHidden}>{props.accessibility_title}</Box>
                    <TableHead>
                        <TableRow>
                            {props.tableHeaders.map(tableHeader => (
                                <TableCell key={`header-${tableHeader}`} component="th" sx={thSX}>{tableHeader}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
                {props.foot_notes}
            </TableContainer>
        );
    };
    
    const TableEmpty = ({ colspan }: { colspan: number }) => {
        return (
            <TableWrapper>
                <TableRow>
                    <TableCell colSpan={colspan} sx={{ borderBottom: 'none' }}>
                        <Typography
                            variant='body1'
                            sx={{
                                // WAVE accessibility contrast error if color matches Figma color of #b8b8b8, so darken it enough to pass (grey.600 = #757575)
                                color: 'grey.600',
                                fontWeight: 500,
                                py: 3.75,
                                textAlign: 'center'
                            }}
                        >{props.empty_message}</Typography>
                    </TableCell>
                </TableRow>
            </TableWrapper>
        );
    };

    const handleExportCsv = async () => {
        downloadCsv(createCsvContent(props.table_data), props.csvFileName ? props.csvFileName : "export.csv");
    };

    return (<>
        <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 2 }}>{props.report_title}</Typography>

        {props.form}

        <Paper sx={cardSX.paperMinHeight}>
            <Container sx={{ py: 4.5 }}>
                {props.hasExport && <Stack direction='row' alignItems='center' sx={{ mb: 3 }}>
                    <Button
                        color='inherit'
                        variant='outlined'
                        onClick={() => handleExportCsv()}
                        sx={{
                        fontSize: '1rem',
                        ml: 'auto',
                        mr: 1.25,
                        textTransform: 'none'
                        }}
                    >
                        Export
                    </Button>
                </Stack>}

                {!props.is_loaded && <TableLoader colsNum={5} rowsNum={props.table_data.length || 5} />}

                {props.is_loaded && props.table_data.length === 0 && <TableEmpty colspan={5} />}

                {props.is_loaded && props.table_data.length > 0 && (
                    <TableWrapper>
                        {props.table_data.map(row => {
                            return props.render_row(row);
                        })}
                    </TableWrapper>
                )}
            </Container>
        </Paper>
    </>);
};

export default ReportTable;